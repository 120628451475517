<div class="shadow-sx col-span-2 mt-3 rounded-xl border border-palette-gray-200 bg-white px-4">
  <ng-container *ngIf="detailReservation?.service_type !== ServiceTypeEnum.AIRPORTTRANSFER">
    <p class="!mb-3 !pt-4 text-base font-semibold text-palette-gray-800">
      {{ 'APP_JOURNEY_INFORMATION.JOURNEY_INFORMATION' | soctripTranslate | async }}
    </p>
    <div class="pb-2">
      <div class="col-span-1 flex rounded-md">
        <div class="col-span-1 w-full !text-md font-normal text-palette-gray-700">
          <div class="grid grid-cols-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1">
                {{ 'APP_JOURNEY_INFORMATION.DAILY_ALLOWABLE_MILEAGE' | soctripTranslate | async }}
              </div>
              <div class="col-span-1 flex justify-end font-medium">
                {{ vehiclePriceData?.daily_mileage_limit }} km
              </div>
            </div>
          </div>
          <div class="grid grid-cols-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1">
                {{ 'APP_JOURNEY_INFORMATION.EXCESS_MILEAGE_FEE' | soctripTranslate | async }}
              </div>
              <div class="col-span-1 flex justify-end font-medium">
                <app-price-format
                  [keepValue]="keepValue"
                  [price]="vehiclePriceData?.overage_fee"
                ></app-price-format
                >/km
              </div>
            </div>
          </div>
          <div class="grid grid-cols-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1">
                {{ 'APP_JOURNEY_INFORMATION.LATE_RETURN_FEE' | soctripTranslate | async }}
              </div>
              <div class="col-span-1 flex justify-end font-medium">
                <app-price-format
                  [keepValue]="keepValue"
                  [price]="vehiclePriceData?.late_return_fee"
                ></app-price-format
                >/{{ 'APP_JOURNEY_INFORMATION.HOUR' | soctripTranslate | async }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isWithDriver" class="border-t"></div>
    <p *ngIf="isWithDriver" class="!mb-3 !pt-4 text-base font-semibold text-palette-gray-800">
      {{ 'APP_JOURNEY_INFORMATION.PICKUP_FEE' | soctripTranslate | async }}
    </p>
    <div class="pb-2" *ngIf="isWithDriver">
      <div class="col-span-1 flex rounded-md text-md font-normal text-palette-gray-700">
        <div class="col-span-1 w-full">
          <div class="grid grid-cols-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1">
                {{ 'APP_JOURNEY_INFORMATION.PICKUP_FEE' | soctripTranslate | async }}
              </div>
              <div
                class="col-span-1 flex justify-end font-medium"
                *ngIf="!!vehiclePriceData?.pick_up_fee"
              >
                <app-price-format
                  [keepValue]="keepValue"
                  [price]="vehiclePriceData?.pick_up_fee"
                ></app-price-format
                >/km
              </div>
              <div *ngIf="!vehiclePriceData?.pick_up_fee">
                {{ 'APP_JOURNEY_INFORMATION.FREE' | soctripTranslate | async }}
              </div>
            </div>
          </div>
          <div class="grid grid-cols-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1">
                {{ 'APP_JOURNEY_INFORMATION.SUPPORT_PICKUP_WITH_IN' | soctripTranslate | async }}
              </div>
              <div class="col-span-1 flex justify-end font-medium">
                {{ vehiclePriceData?.support_pick_up_with_in }} km
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        !isWithDriver &&
        vehiclePriceData?.car_delivery_fee !== 0 &&
        vehiclePriceData?.support_car_delivery_with_in !== 0
      "
      class="border-t"
    ></div>
    <p
      *ngIf="
        !isWithDriver &&
        vehiclePriceData?.car_delivery_fee !== 0 &&
        vehiclePriceData?.support_car_delivery_with_in !== 0
      "
      class="!mb-3 !pt-4 text-base font-semibold text-palette-gray-800"
    >
      {{ 'APP_JOURNEY_INFORMATION.DELIVERY_FEE' | soctripTranslate | async }}
    </p>
    <div
      *ngIf="
        !isWithDriver &&
        vehiclePriceData?.car_delivery_fee !== 0 &&
        vehiclePriceData?.support_car_delivery_with_in !== 0
      "
      class="pb-2"
    >
      <div class="col-span-1 flex rounded-md text-md font-normal text-palette-gray-700">
        <div class="col-span-1 w-full">
          <div class="grid grid-cols-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1">
                {{ 'APP_JOURNEY_INFORMATION.CAR_DELIVERY_FEE' | soctripTranslate | async }}
              </div>
              <div class="col-span-1 flex justify-end font-medium">
                <app-price-format
                  [keepValue]="keepValue"
                  [price]="vehiclePriceData?.car_delivery_fee"
                ></app-price-format
                >/km
              </div>
            </div>
          </div>
          <div class="grid grid-cols-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1">
                {{
                  'APP_JOURNEY_INFORMATION.SUPPORT_CAR_DELIVERED_WITH_IN' | soctripTranslate | async
                }}
              </div>
              <div class="col-span-1 flex justify-end font-medium">
                {{ vehiclePriceData?.support_car_delivery_with_in }} km
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="border-t"></div>
  </ng-container>

  <p class="!mb-3 !pt-4 text-base font-semibold text-palette-gray-800">
    {{ 'APP_JOURNEY_INFORMATION.POTENTIAL_SURCHARGE' | soctripTranslate | async }}
  </p>
  <div class="pb-2">
    <div class="col-span-1 flex rounded-md text-md font-normal text-palette-gray-700">
      <div class="col-span-1 w-full" [ngSwitch]="detailReservation?.service_type">
        <ng-container *ngSwitchCase="ServiceTypeEnum.AIRPORTTRANSFER">
          <div class="grid grid-cols-1 gap-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1 text-md text-palette-gray-900">
                {{
                  'page.ota.airport_transfer.create_edit_service.waiting_fee'
                    | soctripTranslate
                    | async
                }}
              </div>
              <div class="col-span-1 flex justify-end text-md font-medium text-palette-gray-900">
                <app-price-format
                  *ngIf="detailReservation?.vehicle?.waiting_fee! > 0; else freeTmpl"
                  [keepValue]="keepValue"
                  [price]="detailReservation?.vehicle?.waiting_fee || 0"
                ></app-price-format>
                <ng-template #freeTmpl>
                  <span>{{ 'APP_JOURNEY_INFORMATION.FREE' | soctripTranslate | async }}</span>
                </ng-template>
              </div>
            </div>
            <span class="!text-sm text-palette-gray-500 md:pr-[5rem]">
              {{ 'APP_JOURNEY_INFORMATION.waiting_fee_info' | soctripTranslate | async }}
            </span>
          </div>

          <div class="grid grid-cols-1 gap-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1 text-md text-palette-gray-900">
                {{
                  'page.ota.airport_transfer.create_edit_service.extra_baggage_fee'
                    | soctripTranslate
                    | async
                }}
              </div>
              <div class="col-span-1 flex justify-end text-md font-medium text-palette-gray-900">
                <app-price-format
                  *ngIf="detailReservation?.vehicle?.extra_bag_fee! > 0; else freeTmpl"
                  [keepValue]="keepValue"
                  [price]="detailReservation?.vehicle?.extra_bag_fee || 0"
                ></app-price-format>
                <ng-template #freeTmpl
                  >fdsfd
                  <span>{{ 'APP_JOURNEY_INFORMATION.FREE' | soctripTranslate | async }}</span>
                </ng-template>
              </div>
            </div>
            <span class="!text-sm text-palette-gray-500 md:pr-[5rem]">
              {{ 'APP_JOURNEY_INFORMATION.extra_baggage_fee_info' | soctripTranslate | async }}
            </span>
          </div>

          <div class="grid grid-cols-1 gap-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1 text-md text-palette-gray-900">
                {{
                  'page.ota.airport_transfer.create_edit_service.late_night_morning_surcharge'
                    | soctripTranslate
                    | async
                }}
              </div>
              <div class="col-span-1 flex justify-end text-md font-medium text-palette-gray-900">
                <app-price-format
                  *ngIf="detailReservation?.vehicle?.late_night_morning_fee! > 0; else freeTmpl"
                  [keepValue]="keepValue"
                  [price]="detailReservation?.vehicle?.late_night_morning_fee || 0"
                ></app-price-format>
                <ng-template #freeTmpl
                  >fdsfd
                  <span>{{ 'APP_JOURNEY_INFORMATION.FREE' | soctripTranslate | async }}</span>
                </ng-template>
              </div>
            </div>
            <span class="!text-sm text-palette-gray-500 md:pr-[5rem]">
              {{
                'APP_JOURNEY_INFORMATION.late_night_morning_surcharge_info'
                  | soctripTranslate
                  | async
              }}
            </span>
          </div>

          <div class="grid grid-cols-1 gap-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1 text-md text-palette-gray-900">
                {{
                  'page.ota.airport_transfer.create_edit_service.toll_park_fees'
                    | soctripTranslate
                    | async
                }}
              </div>
              <div class="col-span-1 flex justify-end text-md font-medium text-palette-gray-900">
                <app-price-format
                  *ngIf="detailReservation?.vehicle?.toll_park_fee! > 0; else freeTmpl"
                  [keepValue]="keepValue"
                  [price]="detailReservation?.vehicle?.toll_park_fee || 0"
                ></app-price-format>
                <ng-template #freeTmpl
                  >fdsfd
                  <span>{{ 'APP_JOURNEY_INFORMATION.FREE' | soctripTranslate | async }}</span>
                </ng-template>
              </div>
            </div>
            <span class="!text-sm text-palette-gray-500 md:pr-[5rem]">
              {{ 'APP_JOURNEY_INFORMATION.toll_park_fees_info' | soctripTranslate | async }}
            </span>
          </div>

          <div class="grid grid-cols-1 gap-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1 text-md text-palette-gray-900">
                {{
                  'page.ota.airport_transfer.create_edit_service.child_seat_fee'
                    | soctripTranslate
                    | async
                }}
              </div>
              <div class="col-span-1 flex justify-end text-md font-medium text-palette-gray-900">
                <app-price-format
                  *ngIf="detailReservation?.vehicle?.child_seat_fee! > 0; else freeTmpl"
                  [keepValue]="keepValue"
                  [price]="detailReservation?.vehicle?.child_seat_fee || 0"
                ></app-price-format>
                <ng-template #freeTmpl
                  >fdsfd
                  <span>{{ 'APP_JOURNEY_INFORMATION.FREE' | soctripTranslate | async }}</span>
                </ng-template>
              </div>
            </div>
            <span class="!text-sm text-palette-gray-500 md:pr-[5rem]">
              {{ 'APP_JOURNEY_INFORMATION.child_seat_fee_info' | soctripTranslate | async }}
            </span>
          </div>

          <div class="grid grid-cols-1 gap-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1 text-md text-palette-gray-900">
                {{
                  'page.ota.airport_transfer.create_edit_service.flight_delay_fee'
                    | soctripTranslate
                    | async
                }}
              </div>
              <div class="col-span-1 flex justify-end text-md font-medium text-palette-gray-900">
                <app-price-format
                  *ngIf="detailReservation?.vehicle?.flight_delay_fee! > 0; else freeTmpl"
                  [keepValue]="keepValue"
                  [price]="detailReservation?.vehicle?.flight_delay_fee || 0"
                ></app-price-format>
                <ng-template #freeTmpl
                  >fdsfd
                  <span>{{ 'APP_JOURNEY_INFORMATION.FREE' | soctripTranslate | async }}</span>
                </ng-template>
              </div>
            </div>
            <span class="!text-sm text-palette-gray-500 md:pr-[5rem]">
              {{ 'APP_JOURNEY_INFORMATION.flight_delay_fee_info' | soctripTranslate | async }}
            </span>
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div class="grid grid-cols-1 gap-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1">
                {{ 'APP_JOURNEY_INFORMATION.CLEANING_SURCHARGE' | soctripTranslate | async }}
              </div>
              <div class="col-span-1 flex justify-end font-medium">
                <app-price-format
                  [keepValue]="keepValue"
                  [price]="vehiclePriceData?.cleaning_surcharge"
                ></app-price-format>
              </div>
            </div>
            <span class="!text-sm">
              {{ 'APP_JOURNEY_INFORMATION.CANCELLATION_FEE_INFO' | soctripTranslate | async }}
            </span>
          </div>
          <div class="grid grid-cols-1 gap-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1">
                {{ 'APP_JOURNEY_INFORMATION.SMELL_REMOVAL_SURCHARGE' | soctripTranslate | async }}
              </div>
              <div class="col-span-1 flex justify-end font-medium">
                <app-price-format
                  [keepValue]="keepValue"
                  [price]="vehiclePriceData?.smell_removal_surcharge"
                ></app-price-format>
              </div>
            </div>
            <span class="!text-sm">
              {{
                'APP_JOURNEY_INFORMATION.SMELL_REMOVAL_SURCHARGE_INFO' | soctripTranslate | async
              }}
            </span>
          </div>
          <div class="grid grid-cols-1 gap-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1">
                {{ 'APP_JOURNEY_INFORMATION.OTHER_FEE' | soctripTranslate | async }}
              </div>
              <div class="col-span-1 flex justify-end font-medium">
                {{ 'APP_JOURNEY_INFORMATION.PAY_BY_RENTER' | soctripTranslate | async }}
              </div>
            </div>
            <span class="!text-sm">
              {{ 'APP_JOURNEY_INFORMATION.SOME_FEES_MAY_ARISE' | soctripTranslate | async }}
            </span>
            <div
              class="mt-[2px] flex items-start gap-2 rounded-lg border border-branding-primary-100 bg-branding-primary-50 p-3 text-branding-primary-600"
            >
              <p class="text-md font-normal">
                {{
                  'APP_JOURNEY_INFORMATION.DELIVERY_FEE_AND_POTENTIAL_SURCHARGE'
                    | soctripTranslate
                    | async
                }}
              </p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
