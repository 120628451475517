import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Overlay, OverlayModule } from 'primeng/overlay';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { DividerModule } from 'primeng/divider';
import { HeaderService } from '@modules/car-booking/services/header/header.service';
import { UserInfo } from '@modules/users/models/user';
import { UserService } from 'src/app/core/services/user/user.service';
import { DialogModule } from 'primeng/dialog';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import {
  HeaderLibModule,
  HeaderComponent as HeaderLibComponent,
} from '@soctrip-common/master-menu';
import {
  SocialAuthServiceConfig,
  SocialAuthService,
  SocialLoginModule,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from '@abacritt/angularx-social-login';
import { LanguageService } from '@shared/services/language.service';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import { TimezoneService } from '@shared/services/timezone.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    /* @primeng */
    MenubarModule,
    ButtonModule,
    CommonModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    FormsModule,
    TooltipModule,
    DividerModule,
    OverlayModule,
    DialogModule,
    TranslateModule,
    SharedModule,
    HeaderLibModule,
    SocialLoginModule,
  ],
  providers: [
    SocialAuthService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.socialAuthenIds.google, {
              oneTapEnabled: false,
            }),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.socialAuthenIds.facebook),
          },
        ],
        onError: (err: any) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  megaVisible = false;
  readonly myBookingUrl = environment.CAR_URL + '/' + environment.prefix + '/my-booking';
  readonly environment = {
    baseURL: environment.BASE_API_URL,
    BASE_API_URL: environment.BASE_API_URL,
    SOCIAL_APP_URL: environment.SOCIAL_APP_URL,
    TRAVEL_ASSIST_URL: environment.TRAVEL_ASSIST_URL,
    BOOKING_URL: environment.HOTEL_URL,
    ECOMMERCE_FOOD_URL: environment.ECOMMERCE_FOOD_URL,
    ECOMMERCE_URL: environment.ECOMMERCE_URL,
    CAR_RENTAL_URL: environment.CAR_URL + '/' + environment.prefix,
    FLIGHT_URL: environment.FLIGHT_URL,
    SOCTRIP_CENTER_URL: environment.SOCTRIP_CENTER_URL,
    AFFILIATE_URL: 'https://travel-affiliate.beta.soctrip.com',
    languages: ['en', 'vi'],
    socialAuthenIds: {
      google: '802332645501-0aiir74m36114t6v974bcrg4jrf8k1do.apps.googleusercontent.com',
      facebook: '2619489051654463',
    },
    production: environment.production,
    ECOMMERCE_MEGALIVE_CENTRE_URL: environment.ECOMMERCE_MEGALIVE_CENTRE_URL,
  };
  @ViewChildren('fullName') fullNameElements: QueryList<ElementRef>;
  @ViewChild('searchElement') searchElement: ElementRef;
  urlParts: string[] = [];
  currentPage = 'booking';
  scrolled = false;
  headerElement: HTMLElement;
  classIconNavigate =
    'h-10 px-1 lg:px-2 lg:space-x-2 flex justify-center items-center hover:bg-black hover:bg-opacity-[0.15] rounded-md cursor-pointer';
  menuItemColor = '';
  openSearch = false;
  searchValue = '';
  cartNumberBadge = 5;
  noText: boolean;
  isVoice: boolean;
  isShowHeader: boolean;
  isFixedHeader: boolean;
  isWhite: boolean;
  isChangeColorHeader: boolean;
  overlayVisible = false;
  overlayUserVisible = false;
  overlayMoreVisible = false;
  visiblePopupLogout = false;
  visiblePopupDeactivateAccount = false;
  visibleSelectLang = false;
  userInfo: UserInfo;
  isLogin: boolean | null;
  bookingTypeList: any = [];
  menuList: any = [];
  isMiniMenuOpen = false;
  urlTravelAssistant = environment.TRAVEL_ASSIST_URL;
  isShowBookingNav = false;
  menuListTabletScreen: any = [];
  public fullNameArray: any;
  baseUrl = environment.BASE_PATH;
  anonymousUserId: string;
  remindPositionSetting: string =
    'flex flex-col gap-1 items-end fixed md:mt-[-4.25rem] md:top-[5.625rem] md:right-5 md:mx-0 mx-2';
  countryCode = '';
  currentTimeZone: any;

  constructor(
    private router: Router,
    private headerService: HeaderService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private renderer: Renderer2,
    private languageService: LanguageService,
    private soctripTranslationService: SoctripTranslationService,
    private timezoneService: TimezoneService
  ) {}
  ngOnInit() {
    this.anonymousUserId = localStorage.getItem('device_id')! || '';
    this.initData();
    this.getUserInfor();
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.urlParts = e.url.split('/');
        this.headerElement = document.getElementById('menu-bar') as HTMLElement;
        this.handleScroll();
      }
    });
    this.headerService.getPropertyHeader().subscribe((data) => {
      if (data.isShow != undefined) {
        this.isShowHeader = data.isShow;
      }
      if (data.isFixed != undefined) {
        this.isFixedHeader = data.isFixed;
      }
      if (data.isChangeColor != undefined) {
        this.isChangeColorHeader = data.isChangeColor;
      }
      if (data.isWhite != undefined) {
        this.isWhite = data.isWhite;
      }
    });

    this.userService.getLoginStatus().subscribe((data) => {
      if (data !== null) {
        this.isLogin = data;
      }
    });
    let currentCountryCode = localStorage.getItem('currentCountryCode');
    if (currentCountryCode) {
      this.countryCode = currentCountryCode;
    } else {
      this.countryCode = 'US';
    }
    this.currentTimeZone = this.timezoneService.getTimezoneMapping(
      this.countryCode.toLocaleUpperCase()
    );
  }
  changeLanguage(event: string) {
    // const lang = this.isLogin
    //   ? localStorage.getItem('lang')
    //   : localStorage.getItem(environment.UN_AUTH_LANG);
    // if (lang?.toLocaleLowerCase() !== event.toLocaleLowerCase()) {
    this.soctripTranslationService.setLanguage(event);
    window.location.reload();
    // }
  }

  getUserInfor() {
    this.userService.getUserInfor().subscribe((user) => {
      if (user) this.userInfo = user;
    });
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public handleShowTooltip(els: ElementRef[], className: string): void {
    for (var val of els) {
      const isTruncated = val.nativeElement.scrollWidth > val.nativeElement.clientWidth;
      if (isTruncated) {
        this.renderer.addClass(val.nativeElement, className);
      } else {
        this.renderer.removeClass(val.nativeElement, className);
      }
    }
  }

  public mouseOverHotelName() {
    this.fullNameArray = this.fullNameElements.toArray();
    this.handleShowTooltip(this.fullNameArray, 'full-name');
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    this.handleScroll();
  }
  handleMenuBar() {
    if (this.isShowHeader) {
      if (this.scrolled || this.isWhite) return 'bg-white text-palette-gray-500 shadow-md';
    } else {
      return 'hidden';
    }
    return 'text-palette-gray-500 bg-white bg-opacity-[0.32]';
  }
  handleClickNav(event: Event, menuItemName: any) {
    //console.log('menuitme',menuItemName)
    if (menuItemName.menu_item_short_name == 'booking') {
      // this.bookingList.onShow(event);
      this.overlayVisible = !this.overlayVisible;
    } else {
      this.currentPage = menuItemName;
      location.href = menuItemName.url;
    }
  }
  showNoText() {
    this.noText = true;
    this.isVoice = false;
  }
  showVoice() {
    this.isVoice = !this.isVoice;
    this.noText = false;
    alert('This feature is developing.');
  }
  handleScroll() {
    if (!this.headerElement) return;
    if (this.headerElement && window.pageYOffset > 50 && this.urlParts.length < 4) {
      this.scrolled = true;
    } else {
      this.scrolled = false;
    }
  }
  handleClickTypeBooking(typeBooking: any) {
    this.currentPage = 'booking';
    this.overlayVisible = !this.overlayVisible;
    //console.log(typeBooking);
    //this.router.navigateByUrl(typeBooking.url);
    location.href = typeBooking.url;
    //this.router.navigate(['/' + this.currentPage + '/' + typeBooking]);
  }

  changeColorMenuItem(menuItemName: string): string {
    let className = '';
    if (this.currentPage == menuItemName) {
      if (this.scrolled || this.isWhite) {
        className = 'text-palette-blue-600 font-semibold bg-palette-blue-50';
      } else {
        className = 'bg-palette-gray-900 bg-opacity-[0.4] font-semibold';
      }
    }
    return className;
  }
  getIcon(menuItem: any) {
    if (this.scrolled || this.isWhite) {
      if (this.currentPage == menuItem.menu_item_short_name) {
        return menuItem.blue_icon;
      } else {
        return menuItem.gray_icon;
      }
    } else {
      if (this.currentPage == menuItem.menu_item_short_name) {
        return menuItem.active_white_icon;
      } else {
        return menuItem.white_icon;
      }
    }
  }
  handleSearchIcon() {
    this.openSearch = !this.openSearch;
    setTimeout(() => {
      if (this.openSearch && this.searchElement) {
        this.searchElement.nativeElement.focus();
      }
    }, 0);
  }
  getClassSearch() {
    if (this.openSearch) {
      if (this.scrolled || this.isWhite) {
        return 'border rounded-full border-blue-palette-300 shadow-xs';
      } else {
        return 'border rounded-full';
      }
    }
    return '';
  }

  onCloseSearch() {
    this.noText = false;
    this.isVoice = false;
    this.openSearch = false;
    this.searchValue = '';
  }
  handleSearch() {
    // TODO
    alert('This feature is developing.');
  }
  handleClearSearch() {
    this.searchValue = '';
    this.searchElement.nativeElement.focus();
  }
  handleClickMenuMore() {
    this.overlayMoreVisible = !this.overlayMoreVisible;
  }
  onClickMyBookingItem() {
    this.router.navigate(['my-booking']);
  }
  onClickSavedListItem() {
    window.location.href = environment.TRAVEL_ASSIST_URL + '/saved-list';
  }

  onClickReviews() {
    this.router.navigate(['reviews']);
  }

  onSignUp() {
    this.isMiniMenuOpen = false;
    this.router.navigate(['sign-up']);
  }
  onSignIn() {
    this.isMiniMenuOpen = false;
    window.location.href = environment.SOCIAL_APP_URL + '/login';
  }
  displayPopupLogout(): void {
    this.visiblePopupDeactivateAccount = false;
    this.visiblePopupLogout = true;
    this.overlayVisible = false;
  }

  closePopupLogout(): void {
    this.visiblePopupLogout = false;
  }
  toggleCustomSetting() {
    this.overlayUserVisible = !this.overlayUserVisible;
  }

  handleSignOut(): void {
    this.router.navigateByUrl('/sign-in');
    localStorage.removeItem(environment.AUTH_STATUS);
    localStorage.removeItem(environment.TOKEN_KEY);
    localStorage.removeItem(environment.USER_PROFILE);
    localStorage.removeItem(environment.ACCESS_TOKEN_EXPIRY_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
    localStorage.removeItem(environment.USER_ROLE_KEY);
    this.visiblePopupLogout = !this.visiblePopupLogout;
    this.userService.setUserInfo(null);
    this.userService.setLoginStatus(false);
  }
  handleOpenMiniMenu(): void {
    this.isMiniMenuOpen = true;
    document.body.style.overflow = 'hidden';
    document.body.style.marginRight = '10px';
  }
  handleCloseMiniMenu(): void {
    this.isMiniMenuOpen = false;
    document.body.style.overflow = 'auto';
    document.body.style.marginRight = '0px';
    this.isShowBookingNav = false;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const windowWidth = window.innerWidth;
    if (windowWidth > 768) {
      this.isMiniMenuOpen = false;
      document.body.style.overflow = 'auto';
    }
  }
  handleStopPropagation(event: Event): void {
    event.stopPropagation();
  }
  handleShowBookingNav(): void {
    this.isShowBookingNav = !this.isShowBookingNav;
  }

  onClickMyServices() {
    this.router.navigate(['ota']);
  }

  initData() {
    this.bookingTypeList = [
      {
        id: '1',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.FLIGHTS',
        short_name: 'flight',
        icon: 'sctr-icon-plane text-palette-blue-700',
        url: environment.FLIGHT_URL,
      },
      {
        id: '2',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.HOTELS',
        short_name: 'hotel',
        icon: 'sctr-icon-building-01 text-palette-pink-600',
        url: environment.HOTEL_URL,
      },
      {
        id: '3',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.CAR_RENTALS',
        short_name: 'car-rental',
        icon: 'sctr-icon-car text-palette-orange-500',
        url: environment.CAR_URL,
      },
      {
        id: '4',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.TOURS',
        short_name: 'tours',
        icon: 'sctr-icon-umbrella-03 text-palette-purple-600',
        url: environment.TOUR_URL,
      },
    ];
    this.menuList = [
      {
        id: '1',
        menu_item_name: 'HEADER.MENU.HOME',
        menu_item_short_name: 'home',
        menu_item_url: environment.SOCIAL_APP_URL,
        white_icon: 'new-feeds.svg',
        active_white_icon: 'active-new-feeds.svg',
        gray_icon: 'gray-new-feeds.svg',
        blue_icon: 'blue-new-feeds.svg',
      },
      {
        id: '2',
        menu_item_name: 'HEADER.MENU.BOOKING',
        menu_item_short_name: 'booking',
        white_icon: 'booking.svg',
        active_white_icon: 'active-booking.svg',
        gray_icon: 'gray-booking.svg',
        blue_icon: 'blue-booking.svg',
      },
      {
        id: '4',
        menu_item_name: 'HEADER.MENU.FOOD',
        menu_item_short_name: 'food',
        menu_item_url: environment.ECOMMERCE_FOOD_URL,
        white_icon: 'food.svg',
        active_white_icon: 'active-food.svg',
        gray_icon: 'gray-food.svg',
        blue_icon: 'blue-food.svg',
      },
      {
        id: '5',
        menu_item_name: 'HEADER.MENU.SHOPPING',
        menu_item_short_name: 'shopping',
        menu_item_url: environment.ECOMMERCE_URL,
        white_icon: 'shopping.svg',
        active_white_icon: 'active-shopping.svg',
        gray_icon: 'gray-shopping.svg',
        blue_icon: 'blue-shopping.svg',
      },
    ];
    this.menuListTabletScreen = [
      {
        id: '1',
        menu_item_short_name: 'travel',
        menu_item_name: 'Travel Assistant',
        click_function: () => (window.location.href = this.urlTravelAssistant),
        menu_item_icon: 'sctr-icon-star-06',
      },
      {
        id: '2',
        menu_item_short_name: 'booking',
        menu_item_name: 'My Booking',
        click_function: () => {
          this.handleCloseMiniMenu();
          this.router.navigate(['my-booking']);
        },
        menu_item_icon: 'sctr-icon-building-07',
      },
      {
        id: '3',
        menu_item_short_name: 'saved',
        menu_item_name: 'Saved List',
        click_function: () => {
          this.handleCloseMiniMenu();
          window.location.href = environment.TRAVEL_ASSIST_URL + '/saved-list';
        },
        menu_item_icon: 'sctr-icon-bookmark',
      },
      {
        id: '4',
        menu_item_short_name: 'services',
        menu_item_name: 'Soctrip center',
        click_function: () => {
          this.handleCloseMiniMenu();
          this.onClickMyServices();
        },
        menu_item_icon: '',
      },
      {
        id: '5',
        menu_item_short_name: 'logout',
        menu_item_name: 'Sign Out',
        click_function: () => {
          this.handleCloseMiniMenu();
          this.displayPopupLogout();
        },
        menu_item_icon: 'sctr-icon-log-out-01',
      },
    ];
  }
  isAllowedToShowRemindOrder(): boolean {
    const currentPath = window.location.pathname;

    return (currentPath === '/' ||
      currentPath === '/booking/car' ||
      currentPath.startsWith('/booking/car/details') ||
      currentPath.startsWith('/booking/car/search')) &&
      !environment.production
      ? true
      : false;
  }
}
