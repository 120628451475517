<div *ngIf="total > 0" class="flex h-[100%] items-center justify-between">
  <div class="flex max-h-[36px] min-h-[36px] items-center justify-center space-x-[6px]">
    <div *ngIf="page !== 'rate-plans'" class="hidden text-sm text-palette-gray-500 md:block">
      {{ 'MY_BOOKING.NUMBER_OF_CARDS_PER_PAGE' | soctripTranslate | async }}
    </div>
    <div *ngIf="page === 'rate-plans'" class="hidden text-sm text-palette-gray-500 md:block">
      {{ 'MY_BOOKING.ROWS_PER_PAGE' | soctripTranslate | async }}
    </div>
    <div>
      <div
        class="select-page-size h-[36px] space-x-2 bg-white max-h-[36px] min-h-[36px] flex items-center cursor-pointer 
                {{
          page !== 'rate-plans'
            ? 'max-w-[61px] min-w-[61px] justify-center'
            : 'max-w-[90px] min-w-[90px] justify-between'
        }}"
        (click)="overlayVisible = !overlayVisible"
      >
        <div class="text-sm text-palette-gray-500">{{ pageSize }}</div>
        <i class="sctr-icon-chevron-down text-xl text-palette-gray-500"></i>
      </div>
      <p-overlay [(visible)]="overlayVisible">
        <ng-template pTemplate="content">
          <div
            class="custom-scroll mt-2 max-h-[160px] min-w-[60px] overflow-y-auto rounded-lg bg-white font-inter shadow-md lg:min-h-fit"
          >
            <div *ngFor="let item of pageSizeList">
              <div
                class="flex h-8 cursor-pointer items-center space-x-2 border-b p-2 text-palette-gray-500 hover:bg-black hover:bg-opacity-[0.15] hover:font-medium"
                (click)="handleChangePageSize(item.page_size)"
              >
                <span class="text-sm">{{ item.page_size }}</span>
              </div>
            </div>
          </div>
        </ng-template>
      </p-overlay>
    </div>
    <div *ngIf="page !== 'rate-plans'" class="hidden text-sm text-palette-gray-500 md:block">
      {{ 'MY_BOOKING.ITEMS' | translate }}
    </div>
  </div>

  <nav aria-label="Pagination" class="!py-3 text-right">
    <ul class="m-0 flex list-none flex-row items-center justify-end p-0">
      <li class="!mx-1 my-0 select-none">
        <button
          class="flex h-9 min-w-[36px] max-w-[36px] cursor-pointer justify-center items-center {{
            page === 'rate-plans' ? 'border border-palette-gray-300 rounded-lg' : ''
          }}"
          [disabled]="current === 1"
          (click)="onPrevious()"
        >
          <i
            class="sctr-icon-chevron-left text-[20px]"
            [ngClass]="
              current === 1
                ? 'cursor-default text-palette-gray-500'
                : 'cursor-pointer text-palette-gray-800'
            "
          ></i>
        </button>
      </li>
      <div *ngIf="page !== 'rate-plans'">
        <div class="flex items-center !gap-[2px]">
          <li *ngFor="let page of pages; index as i">
            <a
              *ngIf="page !== -1; else more"
              class="internal"
              [class.current]="page === current"
              tabindex="0"
              (click)="onGoTo(page)"
              (keyup.enter)="onGoTo(page)"
              >{{ page }}</a
            >
            <ng-template #more>
              <a class="more"> ... </a>
            </ng-template>
          </li>
        </div>
      </div>
      <div *ngIf="page === 'rate-plans'">
        <div class="flex items-center !gap-[2px]">
          <li *ngFor="let page of pages; index as i">
            <a
              *ngIf="page !== -1; else more"
              class="internal"
              [class.currentRatePlan]="page === current"
              tabindex="0"
              (click)="onGoTo(page)"
              (keyup.enter)="onGoTo(page)"
              >{{ page }}</a
            >
            <ng-template #more>
              <a class="more"> ... </a>
            </ng-template>
          </li>
        </div>
      </div>
      <li class="!mx-1 my-0 select-none">
        <button
          class="flex h-9 min-w-[36px] max-w-[36px] cursor-pointer justify-center items-center {{
            page === 'rate-plans' ? 'border border-palette-gray-300 rounded-lg' : ''
          }}"
          [disabled]="current === total"
          (click)="onNext()"
        >
          <i
            class="sctr-icon-chevron-right text-[20px]"
            [ngClass]="
              current === total
                ? 'cursor-default text-palette-gray-500'
                : 'cursor-pointer text-palette-gray-800'
            "
          ></i>
        </button>
      </li>
    </ul>
  </nav>
</div>
