import {
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  QueryList,
  ViewChildren,
  Renderer2,
  Input,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CountdownService } from '@modules/car-booking/services/count-down.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MockPropertyBookingInfo } from '@modules/car-booking/mock/property-booking-info';
import { SharedModule } from '@shared/shared.module';
import { formatCurrency } from '@shared/utils/helper';
import { IContactInfo } from '@modules/car-booking/models/contact-info.model';
import { ContactInfoService } from '@modules/car-booking/services/contact-info/contact-info.service';
import { PopupTimeoutComponent } from '../popup-timeout/popup-timeout.component';
import { SpecialRequestsService } from '@modules/car-booking/services/special-requests/special-requests.service';
import { ReservationService } from '@modules/car-booking/services/reservation-service/reservation.service';
import { PaymentData, BookedRoomsList } from '@modules/car-booking/models/payment-data.model';
import { environment } from '@env/environment';
import { CarDetail } from '@modules/car-booking/models/view-details';
import { CommonIconText } from '../common-icon-text/common-icon-text.component';
import { TranslateService } from '@ngx-translate/core';
import { OrderDetailDTO } from '@car-rental/angular-car-rental-order-service';

@Component({
  selector: 'app-car-highlight',
  templateUrl: './car-highlight.component.html',
  styleUrls: ['./car-highlight.component.scss'],
})
export class CarHighlightComponent {
  @Input() dataDetailCar: any;
  fule: string;
  @Input() displayUnitType: 'SHORT' | 'FULL' = 'FULL';
  @Input() serviceType: any = OrderDetailDTO.ServiceTypeEnum.CARRENTAL;

  public ServiceTypeEnum = OrderDetailDTO.ServiceTypeEnum;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.dataDetailCar?.fuel !== '') {
      this.fule = this.translateService.instant(
        `SEARCH_RESULT.car_list.fule.${(this.dataDetailCar?.fuel as string)?.toLocaleLowerCase()}`
      );
    } else {
      this.fule = '';
    }
  }

  /**
   * get unit fuels function
   */
  getUnitFuels() {
    if (this.dataDetailCar?.fuel === 'Electric') {
      return this.translateService.instant('pluralization.kWh/100km', {
        count: this.dataDetailCar?.fuel_consumption,
      });
    } else if (this.dataDetailCar?.fuel === 'Hydrogen') {
      return this.translateService.instant('pluralization.kg/100km', {
        count: this.dataDetailCar?.fuel_consumption,
      });
    } else {
      if (this.displayUnitType === 'SHORT') {
        return `${this.dataDetailCar?.fuel_consumption}l/100km`;
      } else {
        return this.translateService.instant('pluralization.liters/100km', {
          count: this.dataDetailCar?.fuel_consumption,
        });
      }
    }
  }
}
