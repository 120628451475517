import { Component, Input } from '@angular/core';
import { OrderDetailDTO } from '@car-rental/angular-car-rental-order-service';
import { CarDetail } from '@modules/car-booking/models/view-details';

@Component({
  selector: 'app-journey-information',
  templateUrl: './journey-information.component.html',
  styleUrls: ['./journey-information.component.scss'],
})
export class JourneyInformationComponent {
  @Input() keepValue: boolean = false;
  @Input() vehiclePriceData: any;
  @Input() isWithDriver: boolean;
  @Input() detailReservation: OrderDetailDTO | any;
  ServiceTypeEnum = OrderDetailDTO.ServiceTypeEnum;

  constructor() {}
}
