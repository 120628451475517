import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { UserInfo } from '@modules/users/models/user';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  catchError,
  map,
  of,
  tap,
  throwError,
} from 'rxjs';
import { User } from 'src/app/data/users/models/user.model';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  basePath = environment.baseURL;
  authStatus = JSON.parse(localStorage.getItem(environment.AUTH_STATUS)!)?.isLoggedIn;
  private isLoginSubject = new BehaviorSubject<boolean | null>(this.authStatus ?? null);
  private _userInfo: ReplaySubject<UserInfo | null> = new ReplaySubject();
  private userInfo$: Observable<UserInfo | null> = this._userInfo.asObservable();
  currentId: string;
  private userInfo: UserInfo | null;

  constructor(private http: HttpClient) {}
  getUserInfo(id: string) {
    //TODO: Replace with actual code
    // return this.http.get(`${this.basePath}/auth/user-info/` + id);
    return of(['admin']);
  }
  getUserInfoById(userId: string): Observable<any> {
    return this.http.get<UserInfo>(this.basePath + `/user/users/${userId}`).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }
  saveUserInfoToLocalStorage(userRole: string[]) {
    localStorage.setItem(environment.USER_ROLE_KEY, JSON.stringify(userRole));
  }
  getUserInfoFromLocalStorage() {
    const userInfoString = localStorage.getItem(environment.USER_ROLE_KEY);
    if (userInfoString) return JSON.parse(userInfoString);
  }
  clearUserLocalStorage() {
    localStorage.removeItem(environment.USER_ROLE_KEY);
  }
  setUserInfo(userInfo: UserInfo | null) {
    this._userInfo.next(userInfo);
  }
  getUserInfor() {
    return this.userInfo$;
  }

  setLoginStatus(isLogin: boolean): void {
    this.isLoginSubject.next(isLogin);
  }

  getLoginStatus(): Observable<boolean | null> {
    return this.isLoginSubject.asObservable();
  }
  setLoginStatusToLocalStorage(isLogin: boolean): void {
    localStorage.setItem(environment.AUTH_STATUS, JSON.stringify({ isLoggedIn: isLogin }));
  }

  getLoginStatusFromLocalStorage() {
    const userInfoString = localStorage.getItem(environment.AUTH_STATUS);
    if (userInfoString) return JSON.parse(userInfoString);
  }
  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }

  getUserUuid() {
    const user = JSON.parse(localStorage.getItem(environment.USER_PROFILE) || '{}');
    //IF: Logged in => Use userId
    //ELSE: use uuid
    return user.id ? user.id : localStorage.getItem(environment.USER_UUID);
  }

  setUserUuid() {
    localStorage.setItem(environment.USER_UUID, uuidv4());
  }

  getUserProfileFromLocalStorage() {
    const userProfileString = localStorage.getItem(environment.USER_PROFILE);
    if (userProfileString) return JSON.parse(userProfileString);
  }

  getUserData(userId: string): Observable<any> {
    if (this.userInfo && this.currentId === userId) {
      return of({
        success: true,
        data: this.userInfo,
        error: null,
      });
    }
    return this.http.get<UserInfo>(this.basePath + `/user/users/${userId}`).pipe(
      tap((res: any) => {
        if (res['success']) {
          this.userInfo = res['data'] as UserInfo;
          this.currentId = userId;
        }
      })
    );
  }
}
