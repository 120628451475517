import { Component, Input } from '@angular/core';
import { environment } from '@env/environment';
import { getRentType } from '@shared/utils/helper';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';

@Component({
  selector: 'app-rental-document',
  templateUrl: './rental-document.component.html',
  styleUrls: ['./rental-document.component.scss'],
})
export class RentalDocumentComponent {
  @Input() isDependencyPanel: boolean = true;
  @Input() dataDetailCar: any;
  @Input() showTermOfUse: boolean = true;
  currencyConversion: any;
  constructor(public readonly soctripTranslationService: SoctripTranslationService) {}

  ngOnInit(): void {
    this.currencyConversion = JSON.parse(
      localStorage.getItem(environment.CURRENCY_CONVERSATION_DATA) || ''
    );
  }

  getCollateralPrice() {
    if (this.dataDetailCar?.currency === this.currencyConversion?.currency) {
      return this.dataDetailCar?.collateral_price_canal;
    } else {
      const rate = this.currencyConversion?.rate || 1;
      return this.dataDetailCar?.collateral_price * rate;
    }
  }
}
