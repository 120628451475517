import { Injectable } from '@angular/core';
const TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor() {}

  logOut(): void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  saveToken(token: string): void {
    localStorage.removeItem(environment.TOKEN_KEY);
    localStorage.setItem(environment.TOKEN_KEY, token);
  }

  getToken(): string | null {
    return localStorage.getItem(environment.TOKEN_KEY);
  }

  saveRefreshToken(token: string): void {
    localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
    localStorage.setItem(environment.REFRESH_TOKEN_KEY, JSON.stringify({ refreshToken: token }));
  }
  saveAccessTokenExpiry(access_token_expiry_date: string): void {
    localStorage.removeItem(environment.ACCESS_TOKEN_EXPIRY_KEY);
    localStorage.setItem(environment.ACCESS_TOKEN_EXPIRY_KEY, access_token_expiry_date);
  }
  saveRefreshTokenExpiry(refresh_token_expiry_date: string): void {
    localStorage.removeItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
    localStorage.setItem(environment.REFRESH_TOKEN_EXPIRY_KEY, refresh_token_expiry_date);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(environment.REFRESH_TOKEN_KEY);
  }
  clearTokenLocalStorage() {
    localStorage.removeItem(environment.TOKEN_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
    localStorage.removeItem(environment.ACCESS_TOKEN_EXPIRY_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
  }

  private isReceivedMsgSubject = new BehaviorSubject<boolean>(false);

  isReceivedMsg$ = this.isReceivedMsgSubject.asObservable();

  updateIsReceivedMsg(value: boolean): void {
    this.isReceivedMsgSubject.next(value);
  }
}
