import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { CarOtaService } from '../car-ota.service';
import { CheckboxModule } from 'primeng/checkbox';
import { Subject, takeUntil } from 'rxjs';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { environment } from '@env/environment';
@Component({
  selector: 'app-modal-submit',
  standalone: true,
  imports: [CommonModule, SharedModule, CheckboxModule, PdfViewerModule],
  templateUrl: './modal-submit.component.html',
  styleUrls: ['./modal-submit.component.scss'],
})
export class ModalSubmitComponent implements OnInit, OnDestroy {
  dialogConfig = {
    closeFromOutSide: true,
    resizable: false,
    scrollable: false,
    displayCloseButton: true,
    styleClass: 'w-[55.25rem]',
  };
  isShow: boolean = false;
  accept: any;
  type: string;
  destroy$ = new Subject<void>();
  basePath = `${environment.baseURL}/`;
  pdfPath: string = environment.REVIEW_CONTRACT_PDF;
  pdfPathEN: string = environment.REVIEW_CONTRACT_PDF;
  pdfPathVI: string = environment.REVIEW_CONTRACT_PDF_VI;
  zoom: number = 1;
  originalSize: boolean = true;

  constructor(private carOtaService: CarOtaService) {}
  ngOnInit(): void {
    this.carOtaService.showModal.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.isShow = value.boolean;
      this.type = value.type;
      this.getType(this.type);
    });
    const userProfile = JSON.parse(localStorage.getItem(environment.USER_PROFILE)!);
    switch (userProfile?.language?.toLowerCase()!) {
      case 'en': {
        this.pdfPath = environment.REVIEW_CONTRACT_PDF;
        break;
      }
      case 'vi': {
        this.pdfPath = environment.REVIEW_CONTRACT_PDF_VI;
        break;
      }
      default: {
        this.pdfPath = environment.REVIEW_CONTRACT_PDF;
        break;
      }
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  cancel() {
    this.carOtaService.showModal.next({ type: '', boolean: false });
    this.accept = undefined;
  }
  onChangeAccept(e: Event) {
    this.accept = e;
  }
  getType(data: string) {
    this.type = data;
  }
  submit() {
    this.carOtaService.showModal.next({ type: this.type, boolean: false });
    this.carOtaService.submit.next({ type: this.type, boolean: true });
    this.accept = undefined;
  }

  incrementZoom(amount: number) {
    if (this.zoom <= 0.5 && amount < 0) return;
    this.zoom += amount;
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.isShow = false;
    }
  }
}
