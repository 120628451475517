import { Injectable } from '@angular/core';
import Big from 'big.js';

@Injectable({
  providedIn: 'root',
})
export class RoundNumberService {
  roundNumber(number: number | Big, decimals: number = 2): number {
    return Number(Big(number).round(decimals, Big.roundHalfUp).toString());
  }

  roundNumberDown(number: number | Big, decimals: number = 2): number {
    return Number(Big(number).round(decimals, Big.roundDown).toString());
  }
}
