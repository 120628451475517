import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  createUrlTreeFromSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, catchError, delay, map, mergeMap, of, retryWhen, tap, throwError } from 'rxjs';
import { PermissionService } from '../../services/permission/permission.service';
@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  private retries = 0;
  constructor(private permissionService: PermissionService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.permissionService.userRoles.pipe(
      mergeMap((roles) => {
        if (this.permissionService.checkPermission(route.data['roles'], roles)) {
          this.retries = 0; 
          return of(true);
        } else {
          throw new Error('User does not have permission');
        }
      }),
      retryWhen((errors) =>
        errors.pipe(
          tap(() => this.retries++),
          delay(1000),
          mergeMap((error) => (this.retries < 5 ? of(error) : throwError(error)))
        )
      ),
      catchError(() => {
        this.retries = 0; 
        return of(createUrlTreeFromSnapshot(route, ['/']));
      })
    );
  }
}
