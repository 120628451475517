import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchBarService {
  private _queryParams: BehaviorSubject<any> = new BehaviorSubject(undefined);
  private queryParams$: Observable<any> = this._queryParams.asObservable();
  private historySearch: any[];
  constructor() {}

  setItemStorage(key: string, value: any): void {
    this.historySearch = JSON.parse(localStorage.getItem(key) || '[]');
    if (!Array.isArray(this.historySearch)) {
      localStorage.removeItem(key);
      this.historySearch = [];
    }
    if (this.historySearch.length < 6) {
      this.historySearch.push(value);
    } else {
      this.historySearch.shift();
      this.historySearch.push(value);
    }
    localStorage.setItem(key, JSON.stringify(this.historySearch));
  }
  getItemStorage(key: string): any {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : null;
  }
  setQueryParamData(data: any) {
    delete data.num_of_rooms;
    delete data.num_of_children;
    delete data.num_of_adults;
    delete data.child_ages_list;
    // num_of_rooms: this.transmittedData.numberOfRooms,
    // num_of_children: this.transmittedData.numberOfChildren,
    // num_of_adults: this.transmittedData.numberOfAdults,
    // child_ages_list: this.child_ages_list,
    // currency: 'USD',
    this._queryParams.next(data);
  }
  getQueryParamData() {
    return this.queryParams$;
  }
}
