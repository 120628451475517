import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { UserInfo } from '@modules/users/models/user';
import { CAR } from '@shared/constants/routes';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChildren('fullName') fullNameElements: QueryList<ElementRef>;
  baseUrl = environment.BASE_PATH;
  overlayMoreVisible = false;
  overlayUserVisible = false;
  visiblePopupLogout = false;
  isLogin: boolean | null;
  userInfo: UserInfo;
  onDestroy$: Subject<void> = new Subject();
  public fullNameArray: any;
  screenWidth: number;
  notifyVisible: boolean = false;
  notifyMobileVisible: boolean = false;
  badgeCount: string;

  constructor(
    private router: Router,
    private userService: UserService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.getUserInfo();
    this.identifyBreakpoint(window.innerWidth);
    this.userService
      .getLoginStatus()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        if (data !== null) {
          this.isLogin = data;
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getUserInfo() {
    this.userService
      .getUserInfor()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => {
        if (user) {
          this.userInfo = user;
        }
      });
  }

  public mouseOverHotelName() {
    this.fullNameArray = this.fullNameElements.toArray();
    this.handleShowTooltip(this.fullNameArray, 'full-name');
  }

  public handleShowTooltip(els: ElementRef[], className: string): void {
    for (var val of els) {
      const isTruncated = val.nativeElement.scrollWidth > val.nativeElement.clientWidth;
      if (isTruncated) {
        this.renderer.addClass(val.nativeElement, className);
      } else {
        this.renderer.removeClass(val.nativeElement, className);
      }
    }
  }

  onSignIn() {
    window.location.href = environment.SOCIAL_APP_URL + '/login';
  }

  onClickMyBookingItem() {
    this.router.navigate(['my-booking']);
  }

  onClickSavedListItem() {
    window.location.href = environment.TRAVEL_ASSIST_URL + '/saved-list';
  }

  handleClickMenuMore() {
    this.overlayMoreVisible = !this.overlayMoreVisible;
  }

  onClickMyServices() {
    window.location.href = environment.SOCTRIP_CENTER_URL;
  }

  toggleCustomSetting() {
    this.overlayUserVisible = !this.overlayUserVisible;
  }

  displayPopupLogout(): void {
    // this.visiblePopupDeactivateAccount = false;
    this.visiblePopupLogout = true;
    // this.overlayVisible = false;
  }

  closePopupLogout(): void {
    this.visiblePopupLogout = false;
  }

  handleSignOut(): void {
    this.visiblePopupLogout = false;
    this.router.navigateByUrl('/sign-in');
    localStorage.removeItem(environment.AUTH_STATUS);
    localStorage.removeItem(environment.TOKEN_KEY);
    localStorage.removeItem(environment.USER_PROFILE);
    localStorage.removeItem(environment.ACCESS_TOKEN_EXPIRY_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
    localStorage.removeItem(environment.USER_ROLE_KEY);
    this.userService.setUserInfo(null);
    this.userService.setLoginStatus(false);
  }

  handleClickLogo(): void {
    this.router.navigate([`/${CAR.HOME}`]);
  }

  replaceAvatarWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/default_user_avatar.png';
  }

  toggleNotifications() {
    if (this.screenWidth >= 768) {
      this.notifyVisible = !this.notifyVisible;
    } else {
      this.notifyMobileVisible = !this.notifyMobileVisible;
    }
  }

  updateBadge(count: string) {
    this.badgeCount = count;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const screenWidth = (event.target as Window).innerWidth;
    this.identifyBreakpoint(screenWidth);
  }

  identifyBreakpoint(screenWidth: number) {
    this.screenWidth = screenWidth;
  }
}
