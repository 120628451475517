<div
  class="{{
    receivedData.containerClass
  }} filter-nav search-box mt-2 p-[16px] h-full bottom-52 w-full items-center bg-gray-200 rounded-xl lg:bg-white"
  [ngClass]="{ 'iscontainer-search': receivedData.isShowPlace }"
>
  <div class="flex items-center gap-[24px]">
    <div class="flex items-center gap-[8px]" *ngFor="let rentalType of rentalTypeOptions">
      <p-radioButton
        [(ngModel)]="selectedRentalType"
        [value]="rentalType.value"
        (ngModelChange)="setDataSearch()"
      ></p-radioButton>
      <span class="text-[14px] font-medium">{{ rentalType.label | translate }}</span>
    </div>
  </div>

  <div
    *ngIf="selectedRentalType !== 'is_airport_transfer'"
    class="{{
      receivedData.containerClass
    }} filter-nav search-box mt-2 h-full bottom-52 w-full items-center bg-gray-200 lg:flex lg:justify-between lg:bg-white gap-1"
    [ngClass]="{ 'iscontainer-search': receivedData.isShowPlace }"
  >
    <div
      class="container-place relative flex h-full w-full flex-1 items-center !pt-2 pb-2 lg:!w-[28%]"
      [ngClass]="{ isDisabled: receivedData.isShowPlace }"
    >
      <span id="inputPlace" class="p-input-icon-left gray-" #destination>
        <i class="box-icon-service px-2">
          <i class="sctr-icon-marker-pin-02 text-xl leading-5 text-palette-blue-600"></i>
        </i>
        <input
          autocomplete="off"
          id="myInput"
          type="text"
          class="h-[50px] w-full truncate bg-gray-100 text-base font-medium not-italic"
          pInputText
          #inputField
          [(ngModel)]="receivedData.selectedHotel"
          (click)="onClickInputPlace($event)"
          (focus)="onInputPlaceFocus()"
          (blur)="onInputPlaceBlur()"
          (input)="onInputChange($event)"
          [placeholder]="placeholderText | soctripTranslate | async"
          [ngClass]="{
            'isfocus truncate': isFocused,
            'isblur truncate': !isFocused
          }"
        />
      </span>
    </div>
    <!-- <div
      class="xl:!w-[18px] xl:p-2"
      [ngClass]="{ isDisabled: receivedData.isShowPlace }"
    >
      <div
        class="xl:relative xl:z-10 xl:h-[28px] xl:w-full xl:border xl:bg-gray-300 xl:py-[12px]"
      ></div>
    </div> -->
    <div
      class="container-select-date date-range-picker relative flex !h-[50px] w-full flex-1 rounded-lg bg-gray-100 lg:!w-[38%]"
    >
      <div class="relative flex h-full w-full items-center">
        <span class="p-input-icon-left flex items-center" #rangeCalendarCheckIn>
          <i class="box-icon-service px-2">
            <i class="sctr-icon-calendar text-xl leading-5 text-palette-blue-600"></i>
          </i>
          <input
            id="check-in-date"
            #selectDateCheckIn
            type="text"
            class="truncatefont-inter w-full bg-gray-100 text-base font-medium not-italic text-black xl:h-[51px]"
            pInputText
            [(ngModel)]="valueCheckIn"
            (click)="showCalendarDayCheckIn($event)"
            (dblclick)="onDatepickerHide()"
            placeholder="{{ 'SEARCH.PLACEHOLDER.CHECK_IN_DATE' | soctripTranslate | async }}"
            readonly
          />
        </span>
      </div>
      <div class="!w-[18px] p-2">
        <div
          class="relative top-1/2 -translate-y-1/2 xl:z-0 xl:h-[28px] xl:w-full xl:border xl:bg-gray-300 xl:py-[12px]"
        ></div>
      </div>
      <div class="relative flex h-full w-full items-center">
        <span class="p-input-icon-left w-full" #rangeCalendarCheckOut>
          <i class="box-icon-service">
            <i class="sctr-icon-calendar text-xl leading-5 text-palette-blue-600"></i>
          </i>
          <input
            #selectDateCheckout
            type="text"
            id="check-out-date"
            class="xl:input-2 w-full truncate bg-gray-100 font-inter text-base font-medium not-italic text-black placeholder:font-inter placeholder:text-base placeholder:font-medium xl:h-[51px]"
            pInputText
            [(ngModel)]="valueCheckOut"
            (click)="showCalendarDayCheckOut($event)"
            (dblclick)="onDatepickerHide()"
            placeholder="{{ 'SEARCH.PLACEHOLDER.CHECK_OUT_DATE' | soctripTranslate | async }}"
            readonly
          />
        </span>

        <div #rangeCalendarPanel class="custom-overlaypanel absolute top-[58px] bg-slate-500">
          <div
            class="custom-root-element"
            bsDaterangepicker
            [ngClass]=""
            #daterangepicker="bsDaterangepicker"
            [minDate]="currentDate!"
            [maxDate]="maxDate!"
            [bsConfig]="{
                    showWeekNumbers: false,
                    customTodayClass: 'customTodayClass',
                    containerClass:'containerClassCalendar',
                    }"
            [outsideClick]="false"
            (onHidden)="onDatepickerHide()"
            (onShown)="onDatepickerShown()"
            [(bsValue)]="selectedDates"
            (bsValueChange)="onDateRangeChange($event)"
            [style]="{ zIndex: '10000' }"
          ></div>
        </div>
      </div>
    </div>
    <!-- <div class="xl:!w-[18px] xl:p-2">
      <div
        class="xl:z-10 xl:h-[28px] xl:w-full xl:border xl:bg-gray-300 xl:py-[12px]"
      ></div>
    </div> -->
    <!-- <span
      id="selectPeople"
      class="container-select-people relative top-[6px] mr-2 flex !h-[51px] w-full cursor-pointer items-center justify-around space-x-2 rounded-lg bg-white p-3 text-left lg:h-full lg:rounded-xl xl:top-0 xl:!w-[20%]"
      (click)="handleSelectPeople($event)"
      tabindex="0"
      #selectOptionRef
    >
      <div class="mt-1">
        <p-inputSwitch [(ngModel)]="isWithDriver"></p-inputSwitch>
      </div>

      <span *ngIf="isWithDriver">With driver</span>
      <span *ngIf="!isWithDriver">Without driver</span>
    </span> -->
    <div
      *ngIf="!isMap"
      class="container-button !h-full rounded-xl xl:flex xl:!h-[52px] xl:cursor-pointer xl:items-center xl:space-x-2 xl:bg-white"
    >
      <p-button
        [label]="(receivedData.titleSearchButton | soctripTranslate | async) || ''"
        class="search-hotel relative h-full w-full whitespace-nowrap font-normal md:w-fit"
        [ngClass]="{ buttonText: receivedData.isShowPlace }"
        styleClass="w-full"
        appDebounceClick
        (debounceClick)="onClickSearch()"
        [debounceTime]="1000"
      ></p-button>
    </div>
  </div>

  <div
    *ngIf="selectedRentalType === 'is_airport_transfer'"
    class="{{
      receivedData.containerClass
    }} filter-nav search-box mt-2 h-full bottom-52 w-full items-center bg-gray-200 lg:flex lg:justify-between lg:bg-white gap-1"
    [ngClass]="{ 'iscontainer-search': receivedData.isShowPlace }"
  >
    <div class="relative flex w-full gap-2 lg:!w-[60%]">
      <div class="container-place relative flex h-full w-full flex-1 items-center !pt-2 pb-2">
        <span id="inputPlace" class="p-input-icon-left">
          <i class="box-icon-service px-2">
            <i class="sctr-icon-plane text-xl leading-5 text-palette-blue-600"></i>
          </i>
          <input
            autocomplete="off"
            id="myInput"
            type="text"
            class="h-[50px] w-full truncate bg-gray-100 text-base font-medium"
            pInputText
            #inputField
            (click)="onClickAirportInput($event)"
            [(ngModel)]="receivedData.at_airport_name"
            (focus)="onInputPlaceFocus()"
            (blur)="onInputPlaceBlur()"
            (input)="onInputAirportChange($event)"
            placeholder="{{ airportSelectPlaceholder | soctripTranslate | async }}"
            [ngClass]="{
              'isfocus truncate': isFocused,
              'isblur truncate': !isFocused
            }"
          />
        </span>
      </div>
      <div
        class="container-place relative flex h-full w-full flex-1 items-center !pt-2 pb-2"
        [ngClass]="{ isDisabled: receivedData.isShowPlace }"
      >
        <span id="inputPlace" class="p-input-icon-left" #destination>
          <i class="box-icon-service px-2">
            <i class="sctr-icon-marker-pin-02 text-xl leading-5 text-palette-blue-600"></i>
          </i>
          <input
            autocomplete="off"
            id="myInput"
            type="text"
            class="h-[50px] w-full truncate bg-gray-100 text-base font-medium not-italic"
            pInputText
            #inputField
            [(ngModel)]="receivedData.selectedHotel"
            (click)="onClickInputPlace($event)"
            (focus)="onInputPlaceFocus()"
            (blur)="onInputPlaceBlur()"
            (input)="onInputChange($event)"
            [placeholder]="dropoffPlaceholder | soctripTranslate | async"
            [ngClass]="{
              'isfocus truncate': isFocused,
              'isblur truncate': !isFocused
            }"
          />
        </span>
      </div>
      <button
        class="absolute left-1/2 top-1/2 h-[36px] w-[36px] -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-white shadow-sm"
      >
        <i class="sctr-icon-switch-horizontal-01 text-xl text-palette-blue-600"></i>
      </button>
    </div>

    <div
      class="container-select-date date-range-picker relative flex !h-[50px] w-full flex-1 rounded-lg bg-gray-100 lg:!w-[30%]"
    >
      <div class="relative flex h-full w-full items-center">
        <span class="p-input-icon-left flex items-center">
          <i class="box-icon-service px-2">
            <i class="sctr-icon-calendar text-xl leading-5 text-palette-blue-600"></i>
          </i>
          <input
            id="check-in-date"
            type="text"
            class="truncatefont-inter w-full bg-gray-100 text-base font-medium not-italic text-black xl:h-[51px]"
            pInputText
            [(ngModel)]="valueSingleDate"
            (click)="showSingleCalendar($event)"
            (dblclick)="onSingleDatepickerHide()"
            placeholder="{{ 'SEARCH.PLACEHOLDER.CHECK_IN_DATE' | soctripTranslate | async }}"
            readonly
          />
        </span>
      </div>

      <div class="!w-[18px] p-2">
        <div
          class="relative top-1/2 -translate-y-1/2 xl:z-0 xl:h-[28px] xl:w-full xl:border xl:bg-gray-300 xl:py-[12px]"
        ></div>
      </div>
      <div class="relative flex h-full w-full items-center">
        <!-- <span>
          <p-calendar
            [(ngModel)]="selectedTime"
            [timeOnly]="true"
            [showTime]="true"
            class="z-[99999]"
          ></p-calendar>
        </span> -->
        <div #dateCalendarPanel class="custom-overlaypanel absolute top-[58px] bg-slate-500">
          <div
            class="custom-root-element"
            bsDatepicker
            [ngClass]=""
            #datepicker="bsDatepicker"
            [minDate]="currentDate!"
            [bsConfig]="{
                    showWeekNumbers: false,
                    customTodayClass: 'customTodayClass',
                    containerClass:'containerClassCalendar',
                    }"
            [outsideClick]="true"
            (onHidden)="onDatepickerHide()"
            (onShown)="onSingleDatepickerShown()"
            [(bsValue)]="selectedDate"
            (bsValueChange)="onSingleDateChange($event)"
            [style]="{ zIndex: '10000' }"
          ></div>
        </div>
        <div class="t-time-picker-container h-full">
          <p-calendar
            #timePicker
            #name="ngModel"
            [dateFormat]="(dateFormat | soctripTranslate | async) || ''"
            view="date"
            [showTime]="true"
            [timeOnly]="true"
            [(ngModel)]="pickupTime"
            [placeholder]="('SEARCH.PLACEHOLDER.CHECK_IN_TIME' | soctripTranslate | async) || ''"
            [readonlyInput]="true"
            [firstDayOfWeek]="1"
            icon="t-time-picker-icon sctr-icon-clock text-2xl/5 text-palette-blue-600"
            [showIcon]="true"
            styleClass="t-time-picker bg-transparent text-palette-gray-900 border-transparent w-full flex flex-row-reverse h-full focus-within:!border-branding-primary-600"
            inputStyleClass="px-[0.5rem] py-[10px] text-palette-gray-900 font-medium text-base focus:!outline-none focus:!border-none bg-transparent border-none"
            [showClear]="false"
            [appendTo]="'body'"
            [style]="{ zIndex: '10000' }"
            (ngModelChange)="selectPickupTime()"
          >
            <ng-template pTemplate="previousicon">
              <i class="t-arrow-icon sctr-icon-chevron-left"></i>
            </ng-template>

            <ng-template pTemplate="nexticon">
              <i class="t-arrow-icon sctr-icon-chevron-right"></i>
            </ng-template>

            <ng-template pTemplate="clearicon">
              <i class="t-clear-icon sctr-icon-x-close text-lg/4"></i>
            </ng-template>
          </p-calendar>
        </div>
      </div>
    </div>
    <div
      *ngIf="!isMap"
      class="container-button !h-full rounded-xl xl:flex xl:!h-[52px] xl:cursor-pointer xl:items-center xl:space-x-2 xl:bg-white"
    >
      <p-button
        [label]="(receivedData.titleSearchButton | soctripTranslate | async) || ''"
        class="search-hotel relative h-full w-full whitespace-nowrap font-normal md:w-fit"
        [ngClass]="{ buttonText: receivedData.isShowPlace }"
        styleClass="w-full"
        appDebounceClick
        (debounceClick)="onClickSearchAirport()"
        [debounceTime]="1000"
      ></p-button>
    </div>
  </div>
</div>

<app-toast></app-toast>

<p-overlayPanel
  #addressPanel
  [appendTo]="'body'"
  [dismissable]="true"
  (onHide)="onHideAddressOverlay()"
>
  <ng-template pTemplate="content" style="position: relative; top: 20px">
    <div id="addressPanel" class="w-full md:!w-full lg:!w-[652px] xl:h-auto">
      <div class="border-b">
        <p class="!px-4 !py-3 text-base font-semibold xl:!text-lg">
          {{ 'SEARCH.SOME_FAMOUS_PLACES' | soctripTranslate | async }}
        </p>
      </div>
      <div
        class="flex cursor-pointer items-center gap-3 !pl-4 hover:opacity-70"
        (click)="handleCarNearU()"
        *ngIf="selectedRentalType !== 'is_airport_transfer'"
      >
        <img class="m-[10px]" style="height: 20px" src="assets/images/home/icon/mark.svg" alt="" />
        <p class="font-inter text-sm font-medium leading-5">
          {{ 'SEARCH.CAR_NEAR_YOU' | soctripTranslate | async }}
        </p>
      </div>
      <div
        class="search-address-list max-h-[212px] space-y-3 overflow-y-auto xl:max-h-[320px] xl:w-auto"
      >
        <span *ngIf="isLoading; then loading; else loaded"></span>
        <ng-template #loading>
          <div class="progress-spinner flex justify-center" *ngIf="true">
            <p-progressSpinner
              animationDuration="3s"
              [style]="{ width: '30px', height: '30px' }"
            ></p-progressSpinner>
          </div>
        </ng-template>
        <ng-template #loaded>
          <span *ngIf="addressList.length; then hadData; else emptyData"></span>
          <ng-template #emptyData>
            <div class="flex flex-col items-center p-3">
              <i
                class="sctr-icon-search-lg !h-10 !w-10 rounded-[50%] bg-palette-blue-100 text-center text-2xl !leading-10 text-palette-blue-600"
              ></i>
              <div class="mt-2 flex justify-center text-base font-semibold text-palette-gray-900">
                {{ 'SEARCH_RESULT.NO_RESULTS_FOUND' | soctripTranslate | async }}
              </div>
            </div>
          </ng-template>
          <ng-template #hadData>
            <div
              *ngFor="let address of addressList"
              class="address-item flex cursor-pointer items-center justify-between pl-2 hover:bg-gray-100 xl:m-0 xl:h-16"
              (click)="onHotelSelected(address)"
            >
              <div class="flex items-center !py-3 px-2 xl:w-full">
                <div
                  class="{{
                    address.bg_color
                  }} flex h-10 w-10 max-w-[40px] items-center justify-center rounded-full"
                >
                  <div class="!h-5 !w-5 text-center">
                    <i class="{{ address.icon_class }}"></i>
                  </div>
                </div>
                <div>
                  <div class="ml-3 text-sm xl:text-base">
                    {{ address.object_name }}
                  </div>
                  <div class="ml-3 text-sm text-gray-500">
                    {{ address.city }}
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel
  #airportPanel
  [appendTo]="'body'"
  [dismissable]="true"
  (onHide)="onHideAirportOverlay()"
>
  <ng-template pTemplate="content" style="position: relative; top: 20px">
    <div id="airportPanel" class="w-full md:!w-full lg:!w-[652px] xl:h-auto">
      <div
        class="search-address-list max-h-[212px] space-y-3 overflow-y-auto xl:max-h-[320px] xl:w-auto"
      >
        <span *ngIf="isLoading; then loading; else loaded"></span>
        <ng-template #loading>
          <div class="progress-spinner flex justify-center" *ngIf="true">
            <p-progressSpinner
              animationDuration="3s"
              [style]="{ width: '30px', height: '30px' }"
            ></p-progressSpinner>
          </div>
        </ng-template>
        <ng-template #loaded>
          <span *ngIf="airportList.length; then hadData; else emptyData"></span>
          <ng-template #emptyData>
            <div class="flex flex-col items-center p-3">
              <i
                class="sctr-icon-search-lg !h-10 !w-10 rounded-[50%] bg-palette-blue-100 text-center text-2xl !leading-10 text-palette-blue-600"
              ></i>
              <div class="mt-2 flex justify-center text-base font-semibold text-palette-gray-900">
                {{ 'SEARCH_RESULT.NO_RESULTS_FOUND' | soctripTranslate | async }}
              </div>
            </div>
          </ng-template>
          <ng-template #hadData>
            <div
              *ngFor="let airport of airportList"
              class="address-item flex cursor-pointer items-center justify-between pl-2 hover:bg-gray-100 xl:m-0 xl:h-16"
              (click)="onSelectAirport(airport)"
            >
              <div class="flex items-center !py-3 px-2 xl:w-full">
                <div>
                  <div class="ml-3 text-sm xl:text-base">
                    {{ airport.airport_name }} ({{ airport?.iata_code }})
                  </div>
                  <div class="ml-3 text-sm text-gray-500">
                    {{ airport?.city_name }}, {{ airport?.country }}
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
