import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  BsDatepickerDirective,
  BsDaterangepickerContainerComponent,
  BsDatepickerContainerComponent,
} from 'ngx-bootstrap/datepicker';
import {
  Subject,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  takeUntil,
  tap,
  timeout,
} from 'rxjs';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SearchBarService } from '@modules/car-booking/services/search-bar.service';
import { scrollToTop, setRentalType } from '@shared/utils/helper';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import {
  RecommendPlace,
  RecommendPlaceRequest,
} from '@modules/car-booking/models/recommendPlaces.model';
import { TranslateService } from '@ngx-translate/core';
import { TIME_OUT_REQUEST } from '@shared/constant';
import {
  ISearchBarData,
  SearchBarDataService,
} from '@modules/car-booking/services/search-bar-data/search-bar-data.service';
import { PlaceType } from '@shared/enum/recommend-place.enum';
import { FooterService } from '@modules/car-booking/services/footer/footer.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { viLocale } from 'src/assets/i18n/vi';
import { DateTime } from 'luxon';
import { CustomDateTimeService } from '@shared/services/custom-date-time.service';
import { DateFormat } from '@shared/enum/date-format.enum';
import { ToastType } from '@shared/enum/toast-type';
import { SearchingPlaceControllerService } from '@assistant/angular-map-location-service';
import { GetApiService } from 'src/app/services/get-api.service';
import { LanguageService } from '@shared/services/language.service';
import { Calendar } from 'primeng/calendar';

defineLocale('vi', viLocale);
export type DropdownValue = string | number;

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [MessageService],
})
export class SearchBarComponent implements OnChanges, OnDestroy {
  @ViewChild('addressPanel') addressPanel: OverlayPanel;
  @ViewChild('airportPanel') airportPanel: OverlayPanel;
  @ViewChild('filterAmountPeoplePanel') filterAmountPeoplePanel: OverlayPanel;
  // @ViewChild('selectOptionRef') selectOptionRef: ElementRef;
  @ViewChild('selectDateCheckIn') selectDateCheckIn!: ElementRef;
  @ViewChild('selectDateCheckout') selectDateCheckout!: ElementRef;
  // @ViewChild('rangeCalendarCheckIn') rangeCalendarCheckIn: ElementRef;
  // @ViewChild('rangeCalendarCheckOut') rangeCalendarCheckOut: ElementRef;

  @ViewChild('rangeCalendarPanel') rangeCalendarPanel: OverlayPanel;
  @ViewChild('daterangepicker', { static: false })
  daterangepicker: BsDatepickerDirective;
  @ViewChild('bsDaterangepicker', { read: ElementRef })
  bsDaterangepicker: ElementRef;

  @ViewChild('dateCalendarPanel') dateCalendarPanel: OverlayPanel;
  @ViewChild('datepicker', { static: false })
  datepicker: BsDatepickerDirective;
  @ViewChild('bsDatepicker', { read: ElementRef })
  bsDatepicker: ElementRef;

  // @ViewChild('contentCalendarPanel', { static: true })
  // contentCalendarPanel: ElementRef;

  @Input() receivedData: any;
  @Input() isMap: boolean;

  @Output() searchBarUpdateData = new EventEmitter<Event>();
  @Output() selectDateValue = new EventEmitter<Event>();
  @Output() selectAtDateValue = new EventEmitter<Event>();
  @Output() onSwitchRentalType = new EventEmitter<string>();

  private searchModelChangeSubscription: Subscription;
  private searchAirportChangeSubscription: Subscription;

  pipe = new DatePipe('en-US');
  destination: string;
  currentDate: Date = new Date(Date.now());
  maxDate: Date | null;
  startDate: Date;
  tempDateCheckIn: string | null;
  tempDateCheckOut: string | null;
  searchLocation: string = '';
  searchText: string = '';
  airportSearchText: string = '';
  searchModelChanged: Subject<string> = new Subject<string>();
  searchAirportChanged: Subject<string> = new Subject<string>();
  selectedDates: (Date | undefined)[] | undefined;
  selectedDate: Date | undefined;
  selectedTime: any;
  valueCheckIn: string | null;
  valueCheckOut: string | null;
  valueSingleDate: string | null;
  valueSelectDate: Date[] = [];
  isFocused: boolean = false;
  placeholderText: string = 'Enter place';
  yourLocationText: string;
  isSearchByLocation = false;
  addressList: RecommendPlace[] = [];
  airportList: any[] = [];
  isLoading: boolean = false;
  destroy$: Subject<void> = new Subject();
  dataSearch: ISearchBarData;
  isClickedInput = false;
  // atDirection: number = 0;
  rentalDays: any;
  hidenPicker: boolean = false;
  // selectedLanguage = localStorage.getItem('user_profile');
  language: string = 'en';
  dateFormat: string = 'primeng.dateFormat';
  airportSelectPlaceholder: string;
  dropoffPlaceholder: string;
  rentalTypeOptions: any = [
    {
      label: 'common.component.booking_details_content.without_driver',
      value: 'is_without_driver',
    },
    {
      label: 'common.component.booking_details_content.with_driver',

      value: 'is_with_driver',
    },
    {
      label: 'common.component.booking_details_content.airport_tranfer',
      value: 'is_airport_transfer',
    },
  ];
  selectedRentalType: string;
  countryCode: string = 'VI';
  clientPosition: any;
  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private searchResultService: SearchBarService,
    private soctripTranslationService: SoctripTranslationService,
    private translateService: TranslateService,
    private searchBarDataService: SearchBarDataService,
    private footerService: FooterService,
    private searchingPlaceControllerService: SearchingPlaceControllerService,
    private renderer: Renderer2,
    private customDateTimeService: CustomDateTimeService,
    private getApiSerive: GetApiService,
    private languageService: LanguageService
  ) {
    this.selectedRentalType = route.snapshot.queryParams['rental_type'] || 'is_without_driver';
  }

  ngOnInit(): void {
    // if (this.selectedLanguage !== null) {
    //   const userProfile = JSON.parse(this.selectedLanguage);
    //   if (
    //     typeof userProfile === 'object' &&
    //     'language' in userProfile &&
    //     (userProfile.language as string).toLocaleLowerCase() === 'vi'
    //   ) {
    //     this.localeService.use('vi');
    //   } else {
    //     this.localeService.use('en');
    //   }
    // } else {
    //   this.localeService.use('en');
    // }
    this.getCurrentPosition();
    this.getCountryCode();
    this.language = this.languageService.currentLanguage$.value || 'en';
    this._OnInit();
    this.handleSearchPlaces();
    this.handleSearchAirport();
    this.placeholderText = 'SEARCH.PLACEHOLDER.PLACE';
    this.yourLocationText = this.soctripTranslationService.getTranslation('SEARCH.YOUR_LOCATION');
    this.airportSelectPlaceholder = this.soctripTranslationService.getTranslation(
      'SEARCH.PLACEHOLDER.SELECT_AIRPORT'
    );
    this.dropoffPlaceholder = this.soctripTranslationService.getTranslation(
      'SEARCH.PLACEHOLDER.DROP_OFF_LOCATION'
    );
  }

  getCountryCode() {
    this.getApiSerive.getCurrentRegion().subscribe({
      next: (response) => {
        if (response?.success && response.data[0]) {
          this.countryCode = response.data[0].country_code;
        } else {
          this.countryCode = 'US';
        }
      },
      error: () => {
        this.countryCode = 'US';
      },
    });
  }
  _OnInit() {
    const queryParams = this.route.snapshot.queryParams;

    this.receivedData.at_iata_code = queryParams['at_iata_code'];
    this.footerService.getFooterSetting().subscribe((setting) => {
      this.receivedData.currency = setting.currency;
      this.receivedData.country_code = setting.lang?.country_code;
    });
    this.dataSearch = {
      destination: this.destination,
      rooms: this.receivedData.numberOfRooms,
      check_in: this.receivedData.initCheckInDate!,
      check_out: this.receivedData.initCheckOutDate,
      at_checkin: this.receivedData.initAirportPickupDate,
      currency: this.receivedData.currency,
      country_code: this.receivedData.country_code,
      lat: this.receivedData.latitude,
      lon: this.receivedData.longitude,
      place_id: this.receivedData.id,
      geo_type: this.receivedData.geo_type,
      rental_type: this.receivedData.rental_type,
      at_iata_code: this.receivedData.at_iata_code,
      at_airport_name: this.receivedData.at_airport_name,
      at_pickup_date: this.receivedData.at_pickup_date,
      at_pickup_time: this.receivedData.initAirportPickupTime,
      // at_direction: this.receivedData.at_direction,
    };

    this.searchBarDataService.setSearchData(this.dataSearch);

    this.selectedDates = [this.receivedData.initCheckInDate!, this.receivedData.initCheckOutDate];
    this.selectedDate = this.receivedData.initAirportPickupDate;
    this.pickupTime = this.receivedData.initAirportPickupTime;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['receivedData']) {
      this._OnInit();
    }
  }

  onInputChange(event: Event) {
    if (!this.addressPanel.overlayVisible) {
      this.addressPanel?.show(event);
    }
    const inputSearchElement = event.target as HTMLInputElement;
    this.receivedData.selectedHotel = inputSearchElement.value;
    this.searchModelChanged.next(inputSearchElement.value);
  }
  onInputAirportChange(event: any) {
    if (!this.airportPanel.overlayVisible) {
      this.airportPanel?.show(event);
    }
    const inputSearchElement = event.target as HTMLInputElement;
    this.receivedData.selectedAirport = inputSearchElement.value;
    this.searchAirportChanged.next(inputSearchElement.value);
  }

  handleSearchPlaces() {
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((searchText: string) => {
        const params: RecommendPlaceRequest = {
          keyword: searchText,
        };
        this.searchText = searchText;
        this.getRecommendPlaces(params);
        this.isClickedInput = false;

        this.receivedData.latitude = undefined;
        this.receivedData.longitude = undefined;
      });
  }
  handleSearchAirport() {
    this.searchAirportChangeSubscription = this.searchAirportChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((searchText: string) => {
        this.airportSearchText = searchText;
        this.getSuggestedAirports(this.airportSearchText);
        // this.isClickedInput = false;
      });
  }
  getRecommendPlaces(paramsSearch?: RecommendPlaceRequest) {
    this.isLoading = true;
    this.isSearchByLocation = false;
    const isContainAdministrative =
      this.selectedRentalType === 'is_airport_transfer' ? false : undefined;
    this.searchingPlaceControllerService
      .searchPlace(
        paramsSearch?.keyword,
        undefined,
        undefined,
        undefined,
        isContainAdministrative,
        undefined,
        paramsSearch?.keyword ? undefined : this.clientPosition?.latitude ?? undefined,
        paramsSearch?.keyword ? undefined : this.clientPosition?.longitude ?? undefined
      )
      .pipe(
        takeUntil(this.destroy$),
        timeout(TIME_OUT_REQUEST),
        tap((res: any) => {
          if (res.success) {
            this.addressList = [];

            res.data.data.forEach((item: any) => {
              let resItem: RecommendPlace = {
                object_name: item.main_text,
                object_type: 'CAR',
                city: 'CITY',
                state: 'STATE',
                country_name: '',
                object_image_url: '',
                num_of_objects: 1,
                property_id: '',
                bg_color: '',
                icon_class: '',
                lat: item.lat,
                lon: item.lon,
                id: item.id,
                geo_type: item.geo_type,
              };
              //let offAddress:any[] = item.adt_divisions.split(',');
              resItem.city = item.adt_divisions;
              // if(offAddress.length>2){
              //   resItem.city = offAddress[0] + ', ' + offAddress[1];
              //   resItem.state = offAddress[1];
              // }
              if (this.addressList.filter((e) => e.object_name === item.main_text).length == 0) {
                this.addressList.push(resItem);
              }
            });
          } else if (!res.success && paramsSearch) {
            this.addressList = [];
          }
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();

    // .subscribe((res:any)=>{
    //   if(res.success){
    //     res.data.forEach((item:any)=>{
    //       let resItem: RecommendPlace = {
    //         object_name: item.main_text,
    //         object_type: 'CAR',
    //         city: '',
    //         state: '',
    //         country_name: item.country,
    //         object_image_url: '',
    //         num_of_objects: 1,
    //         property_id: '',
    //         bg_color: '',
    //         icon_class: '',
    //     }
    //     })

    //   }

    // })
    // this.bookingFamousPlacesControllerService.suggestLocations(
    //     body,
    //     DEFAULT_SEARCH_PARAMS.page_size,
    //     this.receivedData.country_code,
    //     paramsSearch?.keyword
    //   )
    //   .pipe(
    //     takeUntil(this.destroy$),
    //     timeout(TIME_OUT_REQUEST),
    //     tap((res: RecommendPlaceResponse) => {
    //       if (res.success) {
    //         this.addressList = this.getPlaceType(res.data!);
    //       } else if (!res.success && paramsSearch) {
    //         this.addressList = [];
    //       }
    //     }),
    //     finalize(() => {
    //       this.isLoading = false;
    //     })
    //   )
    //   .subscribe();
  }

  getPlaceType(places: RecommendPlace[]) {
    return places?.map((address: RecommendPlace) => {
      let icon_class = '';
      let bg_color = '';
      switch (address.object_type) {
        case PlaceType.CITY:
          icon_class = 'sctr-icon-marker-pin-02 text-xl leading-5 text-palette-blue-600';
          bg_color = 'bg-palette-blue-100';
          break;
        case PlaceType.PROPERTY:
          icon_class = 'sctr-icon-building-01 text-xl leading-5 text-palette-blue-600';
          bg_color = 'bg-palette-blue-100';
          break;
        case PlaceType.HISTORY:
          icon_class = 'sctr-icon-clock-fast-forward text-xl leading-5 text-palette-gray-500';
          bg_color = 'bg-palette-gray-200';
          break;
        default:
          break;
      }
      return {
        ...address,
        icon_class,
        bg_color,
      };
    });
  }
  getCurrentPosition() {
    try {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
          const {
            coords: { longitude, latitude },
          } = position;
          this.clientPosition = { latitude, longitude };
        });
      } else {
        this.messageService.add({
          severity: ToastType.WARNING,
          detail: this.soctripTranslationService.getTranslation(
            'SEARCH.BROWSER_NOT_SUPPORT_GEOLOCATION'
          ),
        });
      }
    } catch (error) {
      throw error;
    }
  }
  handleCarNearU() {
    try {
      navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
        const {
          coords: { longitude, latitude },
        } = position;
        this.yourLocationText =
          this.soctripTranslationService.getTranslation('SEARCH.YOUR_LOCATION');
        this.receivedData.selectedHotel = this.yourLocationText;
        this.dataSearch = {
          ...this.dataSearch,
          destination: this.yourLocationText,
        };
        this.searchBarDataService.setSearchData({
          ...this.dataSearch,
          destination: this.yourLocationText,
        });
        this.receivedData.latitude = latitude;
        this.receivedData.longitude = longitude;

        this.addressPanel.hide();
        this.isSearchByLocation = true;
      });
    } catch (error) {
      throw error;
    }
  }

  ngOnDestroy(): void {
    this.searchModelChangeSubscription?.unsubscribe();
    this.searchAirportChangeSubscription?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
    this.hideOverlayAddress();
    // this.hideOverlaySelectPeople();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const dateRangePickerInputs = document.querySelectorAll('.date-range-picker');
    let clickedInsidePicker = false;

    const dateRangePickerArray = Array.from(dateRangePickerInputs);
    for (const input of dateRangePickerArray) {
      if (input.contains(targetElement)) {
        clickedInsidePicker = true;
        break;
      }
    }
    if (!clickedInsidePicker) {
      // const element = document.querySelector('.bs-datepicker') as HTMLElement;
      this.daterangepicker?.hide();
    }
  }

  onClickInputPlace(event: Event) {
    this.addressPanel.show(event);
    if (!this.isClickedInput) {
      this.isClickedInput = true;
    } else {
      return;
    }
    if (
      (this.isSearchByLocation || this.receivedData.latitude, this.receivedData.longitude) &&
      this.receivedData.selectedHotel === this.yourLocationText
    ) {
      this.receivedData.selectedHotel = '';
      this.getRecommendPlaces();
      return;
    }
    // CASE: Already selected hotel
    if (this.receivedData.selectedHotel) {
      this.getRecommendPlaces({ keyword: this.receivedData.selectedHotel });
      return;
    }
    // CASE: First time click
    if (!this.addressList.length && !this.searchText) {
      this.getRecommendPlaces();
    }
  }
  getSuggestedAirports(searchTexT?: string) {
    if (!searchTexT || !searchTexT.trim()) {
      this.airportList = this.countryCode === 'VI' ? DEFAULT_AIRPORT_VN : DEFAULT_AIRPORT_US;
      return;
    }
    this.getApiSerive.getAirportByKeyword(1, 10, searchTexT || '', this.language).subscribe({
      next: (response) => {
        if (response.success) {
          this.airportList = response.data.airports;
        } else {
          this.airportList = [];
        }
      },
      error: () => {
        this.airportList = [];
      },
    });
  }

  onClickAirportInput(event: Event) {
    this.airportPanel.show(event);
    if (!this.isClickedInput) {
      this.isClickedInput = true;
    } else {
      return;
    }

    if (!this.airportList.length && !this.airportSearchText) {
      this.getSuggestedAirports();
    }
  }

  hideOverlayAddress() {
    this.addressPanel.hide();
  }

  onInputPlaceFocus() {
    this.placeholderText = 'SEARCH.PLACEHOLDER.PLACE';
    this.isFocused = true;
  }

  onHideAddressOverlay() {
    this.isClickedInput = false;
    this.receivedData.selectedHotel =
      this.dataSearch.destination || this.route.snapshot.queryParamMap.get('destination');
  }
  onHideAirportOverlay() {
    this.isClickedInput = false;
    // this.receivedData.selectedHotel =
    //   this.dataSearch.destination || this.route.snapshot.queryParamMap.get('destination');
  }
  onInputPlaceBlur() {
    this.isFocused = false;
    if (!this.addressList.length) return; // if not have data and click outside
    if (!this.receivedData.selectedHotel) {
      this.placeholderText = 'SEARCH.PLACEHOLDER.PLACE';
      return;
    }
    if (this.isSearchByLocation) {
      this.dataSearch = {
        ...this.dataSearch,
        destination: this.yourLocationText,
      };
      this.searchBarDataService.setSearchData({
        ...this.dataSearch,
        destination: this.yourLocationText,
      });
      this.receivedData.selectedHotel = this.yourLocationText;
      return;
    }
    this.dataSearch = {
      ...this.dataSearch,
      destination: this.addressList?.[0]?.object_name,
    };
    this.searchBarDataService.setSearchData({
      ...this.dataSearch,
      destination: this.addressList?.[0]?.object_name,
    });
    this.receivedData.property_id = this.addressList?.[0]?.property_id;
    this.receivedData.dest_type = this.addressList?.[0]?.property_id
      ? PlaceType.PROPERTY.toUpperCase()
      : PlaceType.CITY.toUpperCase();
  }

  onDateRangeChange(event: any) {
    if (event && DateTime.fromJSDate(event[0]).isValid && DateTime.fromJSDate(event[1]).isValid) {
      if (DateTime.fromJSDate(event[0]).valueOf() === DateTime.fromJSDate(event[1]).valueOf()) {
        this.valueSelectDate = event;
        this.startDate = event[0];
        this.selectDateValue.emit(event);
        this.valueCheckIn = this.formatDateTimeTemplate(event[0]);
        this.valueCheckOut = this.formatDateTimeTemplate(event[0]);
        this.tempDateCheckIn = this.formatDateTimeTemplate(event[0]);
        this.tempDateCheckOut = this.formatDateTimeTemplate(event[0]);

        this.maxDate = null;
        this.rentalDays = DateTime.fromJSDate(event[1])
          .plus({ day: 1 })
          .diff(DateTime.fromJSDate(event[0]), 'days')
          .days.toFixed(0);
        this.dataSearch = {
          ...this.dataSearch,
          check_in: this.formatDateTimeTemplate(event[0]),
          check_out: this.formatDateTimeTemplate(event[0]),
        };
        this.searchBarDataService.setSearchData({
          ...this.dataSearch,
          check_in: this.formatDateTimeTemplate(event[0]),
          check_out: this.formatDateTimeTemplate(event[0]),
        });
        return;
        // this.messageService.add({
        //   severity: ToastType.ERROR,
        //   detail: this.soctripTranslationService.getTranslation(
        //     'MESSAGE.CHECK_IN_AND_CHECKOUT_MUST_DIFFERENT'
        //   ),
        // });
        // this.valueCheckIn = null;
        // this.valueCheckOut = null;
        // this.tempDateCheckIn = null;
        // this.tempDateCheckOut = null;
        // this.hidenPicker = false;
        // this.dataSearch = {
        //   ...this.dataSearch,
        //   check_in: null,
        //   check_out: null,
        // };
        // this.searchBarDataService.setSearchData({
        //   ...this.dataSearch,
        // });
        // return;
      }

      this.valueSelectDate = event;
      this.startDate = event[0];
      this.selectDateValue.emit(event);
      this.valueCheckIn = this.formatDateTimeTemplate(event[0]);
      this.valueCheckOut = this.formatDateTimeTemplate(event[1]);
      this.tempDateCheckIn = this.formatDateTimeTemplate(event[0]);
      this.tempDateCheckOut = this.formatDateTimeTemplate(event[1]);
      this.maxDate = null;
      this.rentalDays = DateTime.fromJSDate(event[1])
        .plus({ day: 1 })
        .diff(DateTime.fromJSDate(event[0]), 'days')
        .days.toFixed(0);

      this.dataSearch = {
        ...this.dataSearch,
        check_in: this.formatDateTimeTemplate(event[0]),
        check_out: this.formatDateTimeTemplate(event[1]),
      };
      this.searchBarDataService.setSearchData({
        ...this.dataSearch,
        check_in: this.formatDateTimeTemplate(event[0]),
        check_out: this.formatDateTimeTemplate(event[1]),
      });
    } else {
      this.valueCheckIn = null;
    }
    this.hidenPicker = false;
  }
  onSingleDateChange(event: any) {
    if (event) {
      this.selectedDate = event;
      this.valueSingleDate = this.formatDateTimeTemplate(event);
      this.receivedData.at_pickup_date = event;
      this.selectAtDateValue.emit(event);
      this.dataSearch = {
        ...this.dataSearch,
        at_checkin: this.formatDateTimeTemplate(event),
        at_pickup_date: this.valueSingleDate,
      };
      this.searchBarDataService.setSearchData(this.dataSearch);
    }
    // if (event) {
    //   this.selectedDate = event;
    //   this.valueSingleDate = this.formatDateTimeTemplate(event);
    // } else {
    //   this.selectedDate = new Date();
    //   this.valueSingleDate = this.formatDateTimeTemplate(new Date());
    // }
    // this.valueSingleDate = this.formatDateTimeTemplate(event);
    // this.receivedData.at_pickup_date = event;
  }
  customerFooter() {
    const footerHTML = `
      <div class="flex gap-[8px] w-full justify-between items-center">
        <div class="flex flex-col justify-between">
          <p class="font-semibold">
            ${this.valueCheckIn} - ${
      this.valueCheckOut !== null
        ? this.valueCheckOut
        : this.soctripTranslationService.getTranslation('SEARCH.DATE_PICKET_SELECT_END_DATE')
    }
          </p>
          <p class="flex items-center text-sm gap-1">
            <span>${this.soctripTranslationService.getTranslation(
              'SEARCH.DATE_PICKET_RENTALDAYS'
            )}:</span>
            <span>${this.soctripTranslationService.getTranslationWithParam(
              'pluralization.total_days',
              { count: this.rentalDays }
            )}</span>
            <span class="tooltip"></span>
          </p>
        </div>
      </div>
    `;
    // <div class="wrap-btn">
    //   <button class="text-white rounded-lg bg-[#2563eb] py-3 px-5" id="continue-btn">${this.translateService.instant('SEARCH.DATE_PICKET_CONTINUE')}</button>
    // </div>
    const element = document.querySelector('.bs-datepicker') as HTMLElement;
    element.style.display = 'block';
    const elements = document.querySelector('bs-daterangepicker-container') as HTMLElement;

    const existingFooter = document.querySelector('.custom-datepicker-footer') as HTMLElement;
    if (existingFooter) {
      this.renderer.removeChild(element, existingFooter);
    }

    const footer = this.renderer.createElement('div');
    this.renderer.addClass(footer, 'custom-datepicker-footer');
    this.renderer.setProperty(footer, 'innerHTML', footerHTML);
    this.renderer.appendChild(element, footer);
    const continueBtn = document.querySelector('#continue-btn') as HTMLElement;
    if (continueBtn) {
      this.renderer.listen(continueBtn, 'click', () => this.onContinueClick());
    }
    elements.style.zIndex = this.receivedData.zIndexCalendar;
  }

  onContinueClick() {
    this.hidenPicker = true;
    this.daterangepicker.hide();
  }

  // ngAfterViewInit() {
  //   this.daterangepicker.bsValueChange.subscribe((date: Date[]) => {
  //     setTimeout(() => {
  //       this.daterangepicker.show();
  //       this.customerFooter()
  //     });
  //   });
  // }

  showCalendarDayCheckIn(event: Event) {
    this.daterangepicker.hide();
    this.daterangepicker.minDate = new Date(Date.now());
    this.daterangepicker.show();
    this.customerFooter();
  }
  showSingleCalendar(event: Event) {
    this.datepicker.hide();
    this.datepicker.minDate = new Date(Date.now());
    this.datepicker.show();
  }

  showCalendarDayCheckOut(event: Event) {
    this.daterangepicker.show();
    this.customerFooter();
  }

  onDatepickerHide() {
    if (this.valueCheckIn && !this.valueCheckOut) {
      this.valueCheckOut = this.valueCheckIn;
      this.tempDateCheckIn = this.valueCheckIn;
      this.tempDateCheckOut = this.valueCheckIn;
      this.selectedDates = [this.currentDate, this.currentDate];
    } else {
      this.valueCheckIn = this.tempDateCheckIn;
      this.valueCheckOut = this.tempDateCheckOut;
    }
    this.maxDate = null;
    this.currentDate = new Date(Date.now());
    // if(!this.hidenPicker){
    //   this.daterangepicker.show();
    //   this.customerFooter()
    // }
  }

  onSingleDatepickerHide() {
    this.currentDate = new Date(Date.now());
  }
  onDatepickerShown(): void {
    this.selectDateCheckIn?.nativeElement.focus();
    const datePickerInstance: BsDaterangepickerContainerComponent =
      this.daterangepicker['_datepicker'].instance;
    datePickerInstance.valueChange
      .pipe(filter((value) => value && value[0] && !value[1]))
      .subscribe((value: Date[]) => {
        if (value) {
          const [startDate] = value;
          this.valueCheckIn = this.formatDateTimeTemplate(startDate);
          this.valueSelectDate = [startDate, startDate];

          this.currentDate = startDate;
          this.maxDate = DateTime.fromJSDate(startDate).plus({ days: 90 }).toJSDate();
          this.dataSearch = {
            ...this.dataSearch,
            check_in: this.formatDateTimeTemplate(startDate),
            check_out: this.formatDateTimeTemplate(startDate),
          };
          this.searchBarDataService.setSearchData({
            ...this.dataSearch,
            check_in: this.formatDateTimeTemplate(startDate),
            check_out: this.formatDateTimeTemplate(startDate),
          });
          this.selectDateValue.emit(this.valueSelectDate as any);
          this.selectDateCheckout.nativeElement.focus();
          this.valueCheckOut = null;
          this.customerFooter();
        }
      });
  }
  onSingleDatepickerShown() {
    const datePickerInstance: BsDatepickerContainerComponent =
      this.datepicker['_datepicker'].instance;
    datePickerInstance.valueChange.subscribe((value) => {
      this.valueSingleDate = this.formatDateTimeTemplate(value);
      this.selectedDate = value;
      this.dataSearch = {
        ...this.dataSearch,
        at_checkin: this.formatDateTimeTemplate(value),
        at_pickup_date: this.valueSingleDate,
      };
      this.searchBarDataService.setSearchData({
        ...this.dataSearch,
        at_pickup_date: this.valueSingleDate,
      });
      this.selectAtDateValue.emit(value as any);
    });
  }
  onHotelSelected(address: RecommendPlace) {
    this.receivedData.dest_type = address.property_id
      ? PlaceType.PROPERTY.toUpperCase()
      : PlaceType.CITY.toUpperCase();
    this.receivedData.property_id = address.property_id;
    this.receivedData.latitude = address.lat;
    this.receivedData.longitude = address.lon;
    this.receivedData.id = address.id;
    this.receivedData.geo_type = address.geo_type;
    this.dataSearch = {
      ...this.dataSearch,
      destination: address.object_name,
      place_id: address.id,
      geo_type: address.geo_type,
    };
    this.searchBarDataService.setSearchData({
      ...this.dataSearch,
      // destination: address.object_name,
    });
    this.hideOverlayAddress();
  }
  onSelectAirport(airport: any) {
    this.airportSearchText = airport.airport_name;
    this.receivedData.at_airport_name = airport.airport_name;
    this.receivedData.at_iata_code = airport.iata_code;
    this.dataSearch = {
      ...this.dataSearch,
      at_airport_name: airport.airport_name,
      at_iata_code: airport.iata_code,
    };
    this.searchBarDataService.setSearchData({
      ...this.dataSearch,
    });
    this.airportPanel.hide();
  }

  convertDateDefault(date: any) {
    const originalDate = new Date(date);

    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
    const day = String(originalDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  // Handle search
  onClickSearch() {
    if (!this.valueCheckIn || !this.valueCheckOut) {
      this.messageService.add({
        severity: ToastType.ERROR,
        detail: this.soctripTranslationService.getTranslation(
          'MESSAGE.CHECK_IN_AND_CHECKOUT_REQUIRED'
        ),
      });
      return;
    }
    if (
      this.receivedData.selectedHotel === undefined ||
      this.receivedData.selectedHotel?.trim().length === 0
    ) {
      this.messageService.add({
        severity: ToastType.ERROR,
        detail: this.soctripTranslationService.getTranslation('MESSAGE.ENTER_PLACE_OR_HOTEL'),
      });
      return;
    } else {
      if (!this.selectedSeconds) {
        this.selectPickupTime();
        this.receivedData.at_pickup_time =
          this.pickupTime.getHours() * 3600 + this.pickupTime.getMinutes() * 60;
      } else {
        this.receivedData.at_pickup_time = this.selectedSeconds;
      }
      scrollToTop('smooth');
      const value = this.getValueSearchParams();

      this.searchResultService.setItemStorage('data-search-result', value);
      this.searchBarUpdateData.emit(this.receivedData);
    }
  }

  onClickSearchAirport() {
    if (
      this.receivedData.selectedHotel === undefined ||
      this.receivedData.selectedHotel?.trim().length === 0
    ) {
      this.messageService.add({
        severity: ToastType.ERROR,
        detail: this.soctripTranslationService.getTranslation('MESSAGE.ENTER_PLACE_OR_HOTEL'),
      });
      return;
    }
    if (!this.receivedData.at_iata_code) {
      this.messageService.add({
        severity: ToastType.ERROR,
        detail: this.soctripTranslationService.getTranslation('MESSAGE.ENTER_AIRPORT'),
      });
      return;
    }
    if (!this.selectedSeconds) {
      this.selectPickupTime();
      this.receivedData.at_pickup_time =
        this.pickupTime.getHours() * 3600 + this.pickupTime.getMinutes() * 60;
    } else {
      this.receivedData.at_pickup_time = this.selectedSeconds;
    }
    scrollToTop('smooth');
    const value = this.getValueSearchParams();
    this.searchResultService.setItemStorage('data-search-result', value);
    this.searchBarUpdateData.emit(this.receivedData);
  }

  getValueSearchParams() {
    const checkInDate = this.pipe.transform(this?.valueSelectDate[0], 'yyyy-MM-dd') || null;
    const checkOutDate = this.pipe.transform(this.valueSelectDate[1], 'yyyy-MM-dd') || null;
    const atPickupDate =
      this.pipe.transform(
        !isNaN(this.selectedDate?.getTime()!) ? this.selectedDate : new Date().getTime(),
        'yyyy-MM-dd'
      ) || null;
    this.receivedData.rental_type = this.selectedRentalType;
    // this.receivedData.at_direction = this.atDirection;
    if (!this.receivedData.latitude || !this.receivedData.longitude) {
      this.receivedData = {
        ...this.receivedData,
        selectedHotel: this.addressList?.[0]?.object_name,
        latitude: this.addressList?.[0]?.lat,
        longitude: this.addressList?.[0]?.lon,
      };
    }
    let value = {
      ...this.receivedData,
      destination_name: this.receivedData.selectedHotel,
      destination: this.receivedData.selectedHotel,
      country_code: this.receivedData.country_code,
      checkin: checkInDate!,
      checkout: checkOutDate!,
      currency: this.receivedData.currency,
      property_id: this.receivedData.property_id,
      page_num: 0,
      rental_type: this.selectedRentalType,
      // at_direction: this.atDirection,
      at_pickup_date: this.selectedRentalType === 'is_airport_transfer' ? atPickupDate! : null,
      at_pickup_time: this.selectedSeconds,
    };

    setRentalType(this.selectedRentalType);
    if (
      this.isSearchByLocation ||
      this.receivedData.selectedHotel === this.translateService.instant('SEARCH.YOUR_LOCATION')
    ) {
      value = Object.assign(
        {
          latitude: this.receivedData?.latitude,
          longitude: this.receivedData?.longitude,
        },
        value
      );
    }
    return value;
  }

  setDataSearch() {
    this.dataSearch = {
      ...this.dataSearch,
      rental_type: this.selectedRentalType,
    };
    this.searchBarDataService.setSearchData(this.dataSearch);
    this.onSwitchRentalType.emit(this.selectedRentalType);
  }
  updateDirection() {
    // this.atDirection = this.atDirection === 0 ? 1 : 0;
    // this.dataSearch.at_direction = this.atDirection;
    // const value = this.getValueSearchParams();
    // this.searchResultService.setQueryParamData(value);
    this.searchBarDataService.setSearchData(this.dataSearch);
  }
  formatDateTimeTemplate(value: any, time?: string) {
    if (time) {
      return this.customDateTimeService.formatDate(value, DateFormat.DATE_SHORT) + ` ${time}`;
    }
    return this.customDateTimeService.formatDate(value, DateFormat.DATE_SHORT);
  }

  @ViewChild('timePicker') timePicker: Calendar;
  pickupTime: Date = new Date();
  selectedSeconds: number;

  selectPickupTime(): void {
    if (!this.pickupTime) {
      this.pickupTime = new Date();
    }
    this.selectedSeconds = this.pickupTime.getHours() * 3600 + this.pickupTime.getMinutes() * 60;
    this.dataSearch.at_pickup_time = this.selectedSeconds;
    // const value = this.getValueSearchParams();
    // this.searchResultService.setQueryParamData(value);
    this.searchBarDataService.setSearchData(this.dataSearch);
  }
}

const DEFAULT_AIRPORT_VN = [
  {
    city_name: 'Hà Nội',
    country: 'Vietnam',
    airport_name: 'Sân bay Quốc tế Nội Bài',
    iata_code: 'HAN',
    object_type: 'Airport',
  },
  {
    city_name: 'Thành phố Hồ Chí Minh',
    country: 'Vietnam',
    airport_name: 'Sân bay Quốc tế Tân Sơn Nhất',
    iata_code: 'SGN',
    object_type: 'Airport',
  },
  {
    city_name: 'Nha Trang',
    country: 'Vietnam',
    airport_name: 'Sân bay Cam Ranh',
    iata_code: 'CXR',
    object_type: 'Airport',
  },
  {
    city_name: 'Cần Thơ',
    country: 'Vietnam',
    airport_name: 'Sân bay Cần Thơ',
    iata_code: 'VCA',
    object_type: 'Airport',
  },
  {
    city_name: 'Đà Nẵng',
    country: 'Vietnam',
    airport_name: 'Sân bay Quốc tế Đà Nẵng',
    iata_code: 'DAD',
    object_type: 'Airport',
  },
];

const DEFAULT_AIRPORT_US = [
  {
    city_name: 'Los Angeles Metropolitan Area',
    country: 'United States of America',
    airport_name: 'Los Angeles International Airport',
    iata_code: 'LAX',
    object_type: 'Airport',
  },
  {
    city_name: 'Miami',
    country: 'United States of America',
    airport_name: 'Miami International Airport',
    iata_code: 'MIA',
    object_type: 'Airport',
  },
  {
    city_name: 'New York',
    country: 'United States of America',
    airport_name: 'John F. Kennedy International Airport',
    iata_code: 'JFK',
    object_type: 'Airport',
  },
  {
    city_name: 'Chicago',
    country: 'United States of America',
    airport_name: "O'Hare International Airport",
    iata_code: 'ORD',
    object_type: 'Airport',
  },
  {
    city_name: 'San Francisco',
    country: 'United States of America',
    airport_name: 'SFO International Airport',
    iata_code: 'SFO',
    object_type: 'Airport',
  },
];
