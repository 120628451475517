<app-modal (emitOnCloseDialog)="isShow = false" [showDialog]="isShow" [dialogConfig]="dialogConfig">
  <ng-container modal-header>
    <p class="text-xl font-semibold text-[#101828]">
      {{ 'OTA.LEFT_SIDEBAR.REVIEW_CONTRACT' | soctripTranslate | async }}
    </p>
  </ng-container>
  <ng-container modal-content>
    <div class="t-review-contract relative h-full bg-white">
      <pdf-viewer
        [src]="pdfPath"
        [rotation]="0"
        [original-size]="true"
        [show-all]="true"
        [fit-to-page]="true"
        [zoom]="zoom"
        [stick-to-page]="false"
        [render-text]="false"
        [external-link-target]="'blank'"
        [autoresize]="false"
        [show-borders]="true"
        class="h-[100vh] overflow-auto md:h-full md:min-h-[calc(100vh-24rem)]"
      ></pdf-viewer>
      <div class="absolute bottom-[14.5rem] right-4 mx-6 flex gap-2 md:bottom-10">
        <button (click)="incrementZoom(0.1)" class="rounded-full bg-gray-100 p-1 hover:bg-gray-200">
          <span class="sctr-icon-zoom-in"></span>
        </button>
        <button
          (click)="incrementZoom(-0.1)"
          class="rounded-full bg-gray-100 p-1 hover:bg-gray-200"
        >
          <span class="sctr-icon-zoom-out"></span>
        </button>
      </div>
      <div class="ml-6 mt-4">
        <p-checkbox
          [(ngModel)]="accept"
          (ngModelChange)="onChangeAccept($event)"
          value="accept"
          inputId="accept"
        ></p-checkbox>
        <label class="pl-2 text-md font-medium text-[#344054]" for="accept">{{
          'page.ota.car_management.agree_publish' | soctripTranslate | async
        }}</label>
      </div>
    </div>
  </ng-container>
  <ng-container modal-footer>
    <div>
      <button
        (click)="cancel()"
        class="cursor-pointer rounded-lg border border-gray-300 bg-white px-5 py-3 text-md font-semibold hover:bg-gray-200"
      >
        Cancel
      </button>
      <button
        (click)="submit()"
        [ngClass]="[
          accept
            ? 'bg-branding-primary-600 hover:bg-branding-primary-700'
            : 'bg-branding-primary-300 disabled:bg-branding-primary-300'
        ]"
        class="cursor-pointer rounded-lg px-5 py-3 text-md font-semibold text-white"
      >
        Submit
      </button>
    </div>
  </ng-container>
</app-modal>
