import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localizedLangDescString',
  standalone: true,
})
export class LocalizedLangDescStringPipe implements PipeTransform {
  transform(value: Array<{ code: string; desc: string }> | any, lang?: string | null): string {
    let finalLang: string =
      typeof lang === 'string'
        ? lang.toLowerCase()
        : localStorage.getItem('lang')?.toLowerCase() || 'en';

    lang = finalLang;

    if (!Array.isArray(value)) {
      return value;
    }

    const localizedDesc = value.find(
      (item) => item.code.toLowerCase() === lang && item?.desc?.trim()
    )?.desc;
    if (localizedDesc) {
      return localizedDesc;
    }

    const englishDesc = value.find(
      (item) => item.code.toLowerCase() === 'en' && item?.desc?.trim()
    )?.desc;
    if (englishDesc) {
      return englishDesc;
    }

    const fallbackDesc = value.find((item) => item?.desc?.trim())?.desc;
    if (fallbackDesc) {
      return fallbackDesc;
    }

    return value[0]?.desc || '';
  }
}
