import { MY_BOOKING_STATUS } from '@shared/constant';

export const encryptFilterParam = (text: string) => {
  return btoa(text);
};
export const getRentType = () => {
  if (localStorage.getItem('isWithDriver') == '1') {
    return true;
  } else {
    return false;
  }
};

export const getServiceType = () => {
  if (localStorage.getItem('isAirportTransfer') === '1') {
    return true;
  } else {
    return false;
  }
};

export const setServiceType = (isAirportTransfer: boolean) => {
  if (isAirportTransfer) {
    localStorage.setItem('isAirportTransfer', '1');
  } else {
    localStorage.setItem('isAirportTransfer', '0');
  }
};

export const setRentType = (isWithDriver: boolean) => {
  if (isWithDriver) {
    localStorage.setItem('isWithDriver', '1');
  } else {
    localStorage.setItem('isWithDriver', '0');
  }
};
export const getRentalType = () => {
  return localStorage.getItem('rentalType') || 'is_with_driver';
};

export const setRentalType = (type: string) => {
  localStorage.setItem('rentalType', type);
};
export const handleClassCarBookingStatus = (booking_status: string) => {
  switch (booking_status) {
    case 'PENDING':
    case 'Pending payment':
    case MY_BOOKING_STATUS.toPay:
    case MY_BOOKING_STATUS.waitingForConfirm:
      return 'bg-palette-amber-50 text-palette-amber-600';
    case MY_BOOKING_STATUS.completed:
    case MY_BOOKING_STATUS.pendingReview:
    case MY_BOOKING_STATUS.noShow:
      return 'bg-palette-emerald-50 text-palette-emerald-600';
    case 'In progress':
    case MY_BOOKING_STATUS.refundProcessing:
      return 'bg-palette-purple-50 text-palette-purple-600';
    case MY_BOOKING_STATUS.confirmed:
      return 'bg-palette-red-50 text-palette-red-600';
    case MY_BOOKING_STATUS.canceled:
    case MY_BOOKING_STATUS.failed:
      return 'bg-palette-red-50 text-palette-red-700';
    case MY_BOOKING_STATUS.inProgress:
      return 'bg-branding-primary-50 text-branding-primary-600';
    default:
      return 'bg-palette-gray-100 text-palette-gray-700';
  }
};
export const createSlug = (text: string) => {
  const diacriticsMap: { [key: string]: string } = {
    à: 'a',
    á: 'a',
    ả: 'a',
    ã: 'a',
    ạ: 'a',
    ă: 'a',
    ắ: 'a',
    ằ: 'a',
    ẳ: 'a',
    ẵ: 'a',
    ặ: 'a',
    â: 'a',
    ấ: 'a',
    ầ: 'a',
    ẩ: 'a',
    ẫ: 'a',
    ậ: 'a',
    đ: 'd',
    è: 'e',
    é: 'e',
    ẻ: 'e',
    ẽ: 'e',
    ẹ: 'e',
    ê: 'e',
    ế: 'e',
    ề: 'e',
    ể: 'e',
    ễ: 'e',
    ệ: 'e',
    ì: 'i',
    í: 'i',
    ỉ: 'i',
    ĩ: 'i',
    ị: 'i',
    ò: 'o',
    ó: 'o',
    ỏ: 'o',
    õ: 'o',
    ọ: 'o',
    ô: 'o',
    ố: 'o',
    ồ: 'o',
    ổ: 'o',
    ỗ: 'o',
    ộ: 'o',
    ơ: 'o',
    ớ: 'o',
    ờ: 'o',
    ở: 'o',
    ỡ: 'o',
    ợ: 'o',
    ù: 'u',
    ú: 'u',
    ủ: 'u',
    ũ: 'u',
    ụ: 'u',
    ư: 'u',
    ứ: 'u',
    ừ: 'u',
    ử: 'u',
    ữ: 'u',
    ự: 'u',
    ỳ: 'y',
    ý: 'y',
    ỷ: 'y',
    ỹ: 'y',
    ỵ: 'y',
  };
  const noDiacritics = text
    ?.toLowerCase()
    ?.replace(/[\u0300-\u036F]/g, '')
    ?.replace(/[^\u0000-\u007E]/g, (char) => diacriticsMap[char] || char);
  return noDiacritics.replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
};
export const formatCurrency = (price: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(price);
};
export const renderStar = (number: number): any => {
  return [...Array(number)];
};

export const scrollToTop = (behavior?: ScrollBehavior) => {
  window.scrollTo({ top: 0, left: 0, behavior: behavior });
};
